import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/assinatura',
    component: Layout,
    children: [
        {
            name: 'nova-assinatura',
            path: 'nova',
            component: () => import('@/components/assinatura/nova'),
            meta: {
                auth: true
            }
        },
        {
            name: 'reativar-assinatura',
            path: 'reativar',
            component: () => import('@/components/assinatura/reativarExpurgado'),
            meta: {
                auth: true
            }
        }
    ]
}]
