// initial state
const state = {
    collapsedMenu: false,
    showTutorialPlugin: true
}

// getters
const getters = {
}

// actions
const actions = {
    toggleSideMenu (context) {
        context.commit('toggleSideMenu')
    }
}

// mutations
const mutations = {
    toggleSideMenu (state) {
        state.collapsedMenu = !state.collapsedMenu
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
