// initial state
const state = {
    isAuth: false,
    emailSegfyer: '',
    nameSegfyer: '',
    SegfyerId: '',
    email: '',
    username: '',
    usuarioId: '',
    assinaturaId: '',
    assinaturaLogin: '',
    chatHost: '',
    dataAssinatura: null,
    primeiroAcesso: false,
    token: '',
    server: '',
    administrador: false,
    impersonateClient: '',
    impersonateUser: '',
    authTime: null,
    hash: '',
    segfyer: false,
    guid: '',
    dataTrialInicio: null,
    dataTrialFim: null,
    isMC: false,
    isGestao: false,
    isOrcamentacao: false,
    administradorOrcamento: false,
    produtorId: null,
    bloquearOrcamentoVeicular: false,
    bloquearOrcamentoResidencial: false,
    bloquearOrcamentoManual: false,
    bloquearDuplicarOrcamento: false,
    acessoBuscaDocumentos: false,
    acessoRecebimento: false,
    acessoParcelaAtrasada: false,
    nomeCorretora: '',
    cnpjCorretora: '',
    emailIntranet: '',
    temParcelaPendente: false,
    statusAssinatura: '',
    tokenAutomacao: '',
    novaJornada: true,
    authAutomationToken: '',
    acessoCotacaoSaude: false
}

// getters
const getters = {
}

// actions
const actions = {
    updateUserEmail (context, newEmail) {
        context.commit('updateUserEmail', newEmail)
    },
    updateUserInfo (context, data) {
        context.commit('updateUserInfo', data)
    },
    updateAsLoggedIn (context) {
        context.commit('updateAsLoggedIn')
    },
    updateAsLoggedOut (context) {
        context.commit('updateAsLoggedOut')

        if (context.state.userImpersonate) {
            context.commit('forgetMe')
        }
    },
    forgetUser (context) {
        context.commit('forgetUser')
    },
    setHash (context, data) {
        context.commit('setHash', data)
    },
    updateAuthAutomationToken (context, authAutomationToken) {
        context.commit('updateAuthAutomationToken', authAutomationToken)
    }
}

// mutations
const mutations = {
    setHash (state, newHash) {
        state.hash = newHash
    },

    updateUserEmail (state, newEmail) {
        state.email = newEmail
    },

    updateUserInfo (state, newUserData) {
        state.email = newUserData.email
        state.username = newUserData.nome
        state.usuarioId = newUserData.usuarioId
        state.assinaturaLogin = newUserData.assinaturaLogin
        state.assinaturaId = newUserData.assinaturaId
        state.chatHost = newUserData.chatHost
        state.token = newUserData.token
        state.segfyer = newUserData.segfyer
        state.administrador = newUserData.administrador
        state.guid = newUserData.guid
        state.server = newUserData.server
        state.dataTrialInicio = newUserData.dataInclusaoTrial
        state.dataTrialFim = newUserData.dataExpiracaoTrial
        state.dataAssinatura = newUserData.dataAssinatura
        state.primeiroAcesso = newUserData.primeiroAcesso
        state.isMC = newUserData.acessoMC
        state.isGestao = newUserData.acessoGestao
        state.isOrcamentacao = newUserData.acessoOrcamentacao
        state.segfyerId = newUserData.segfyerId
        state.nomeSegfyer = newUserData.nomeSegfyer
        state.emailSegfyer = newUserData.emailSegfyer
        state.administradorOrcamento = newUserData.administradorOrcamento
        state.produtorId = newUserData.produtorId
        state.bloquearOrcamentoVeicular = newUserData.bloquearCotacaoVeicularTelaCotacao
        state.bloquearOrcamentoResidencial = newUserData.bloquearCotacaoResidencialTelaCotacao
        state.bloquearOrcamentoManual = newUserData.bloquearCotacaoManualTelaCotacao
        state.bloquearDuplicarOrcamento = newUserData.bloquearDuplicarCotacaoTelaCotacao
        state.acessoBuscaDocumentos = newUserData.acessoBuscaDocumentos
        state.acessoRecebimento = newUserData.acessoRecebimento
        state.acessoParcelaAtrasada = newUserData.acessoParcelaAtrasada
        state.nomeCorretora = newUserData.nomeCorretora
        state.cnpjCorretora = newUserData.cnpjCorretora
        state.emailIntranet = newUserData.emailIntranet
        state.temParcelaPendente = newUserData.temParcelaPendente
        state.statusAssinatura = newUserData.statusAssinatura
        state.tokenAutomacao = newUserData.tokenAutomacao
        state.acessoConfigurarLoginsTodos = newUserData.acessoConfigurarLoginsTodos
        state.novaJornada = newUserData.novaJornada
        state.authAutomationToken = newUserData.authAutomationToken
        state.acessoCotacaoSaude = newUserData.acessoCotacaoSaude
    },

    updateCompanyInfo (state, newCompanyName) {
        state.nomeCorretora = newCompanyName
     },

     updateAuthAutomationToken (state, authAutomationToken) {
        state.authAutomationToken = authAutomationToken
     },
     
    updateAsLoggedIn (state) {
        state.isAuth = true
        state.authTime = Date.now()
    },

    updateAsLoggedOut (state) {
        state.isAuth = false
        state.authTime = null
    },

    forgetUser (state) {
        state.username = ''
        state.email = ''
        state.usuarioId = ''
        state.assinaturaId = ''
        state.assinaturaLogin = ''
        state.chatHost = ''
        state.isAuth = false
        state.server = ''
        state.token = ''
        state.guid = ''
        state.impersonateClient = ''
        state.impersonateUser = ''
        state.authTime = null
        state.hash = ''
        state.segfyer = false
        state.dataTrialInicio = null
        state.dataTrialFim = null
        state.dataAssinatura = null
        state.administrador = false
        state.isMC = false
        state.isGestao = false
        state.isOrcamentacao = false
        state.SegfyerId = ''
        state.nomeSegfyer = ''
        state.emailSegfyer = ''
        state.administradorOrcamento = false
        state.produtorId = null
        state.bloquearOrcamentoVeicular = false
        state.bloquearOrcamentoResidencial = false
        state.bloquearOrcamentoManual = false
        state.bloquearDuplicarOrcamento = false
        state.acessobuscaDocumentos = false
        state.acessoRecebimento = false
        state.acessoParcelaAtrasada = false
        state.nomeCorretora = ''
        state.cnpjCorretora = ''
        state.emailIntranet = ''
        state.temParcelaPendente = false
        state.statusAssinatura = ''
        state.tokenAutomacao = ''
        state.novaJornada = true
        state.authAutomationToken = ''
        state.acessoCotacaoSaude = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
