var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        "invalid-feedback": _vm.feedbackOrigin,
        description: _vm.descricao
      }
    },
    [
      !_vm.switcher
        ? _c(
            "b-form-checkbox",
            {
              attrs: {
                name: _vm.name,
                variant: "success",
                state: _vm.stateOrigin
              },
              on: { change: _vm.eventoInput },
              model: {
                value: _vm.valor,
                callback: function($$v) {
                  _vm.valor = $$v
                },
                expression: "valor"
              }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.switcher
        ? _c("label", { staticClass: "switcher" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.valor,
                  expression: "valor"
                }
              ],
              staticClass: "switcher-input",
              attrs: {
                type: "checkbox",
                name: _vm.name,
                state: _vm.stateOrigin
              },
              domProps: {
                checked: Array.isArray(_vm.valor)
                  ? _vm._i(_vm.valor, null) > -1
                  : _vm.valor
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.valor,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.valor = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.valor = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.valor = $$c
                    }
                  },
                  function($event) {
                    _vm.eventoInput(_vm.valor)
                  }
                ]
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "switcher-label" }, [
              _vm._v(_vm._s(_vm.label))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "switcher-indicator" }, [
              _c("span", { staticClass: "switcher-yes" }),
              _vm._v(" "),
              _c("span", { staticClass: "switcher-no" })
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }