import Layout from '@/layout/base/layoutSegfy'
import Blank from '@/layout/base/layoutBlank'

export default [{
    path: '/newWindow',
    component: Layout,
    children: [
        {
            path: '',
            component: () => import('@/components/basic/newWindow.vue'),
            meta: {
                auth: true
            } 
        }
    ]
},
{
    path: '/replaceUrl',
    component: Blank,
    children: [
        {
            path: '',
            component: () => import('@/components/basic/replaceUrl.vue'),
            meta: {
                auth: true
            } 
        }
    ]
},
{
    path: '/logoff',
    component: Blank,
    children: [
        {
            path: '',
            component: () => import('@/components/basic/logoff.vue'),
            meta: {
                auth: true
            } 
        }
    ]
},
{
    path: '/unauthorized',
    component: Layout,
    children: [
        {
            path: '',
            component: () => import('@/components/basic/unauthorized.vue'),
            meta: {
                auth: false
            } 
        }
    ]
}
]
