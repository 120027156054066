import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/TelegramBot',
    component: Layout,
    children: [
        {
            path: 'TelegramBot',
            component: () => import('@/components/telegramBot/telegramBot'),
            meta: {
                auth: true
            } 
        }
    ]
}]
