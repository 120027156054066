var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "layout-footer footer", class: _vm.getLayoutFooterBg() },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-fluid container-p-x pb-3" }, [
      _c("a", { staticClass: "footer-link pt-3", attrs: { href: "#" } }, [
        _vm._v("Link 1")
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "footer-link pt-3 ml-4", attrs: { href: "#" } }, [
        _vm._v("Link 2")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }