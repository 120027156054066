const UserPlan = Object.freeze({
    MULTICALCULO: Symbol('multicalculo'),
    INTEGRADO: Symbol('integrado'),    
    GESTAO: Symbol('gestao')
})

const state = {
    userPlan: UserPlan.INTEGRADO,
    user: {
        name: '',
        email: '',
        phone: '',
        password: ''
    },
    company: {
        name: '',
        website: ''
    }
}

const getters = {
}

const actions = {
    updateUserPlan (context, userPlan) {
        context.commit('updateUserPlan', userPlan)
    },
    updateUserInfo (context, name, email, phone, password) {
        context.commit('updateUserInfo', name, email, phone, password)
    },
    updateCompanyInfo (context, name, website) {
        context.commit('updateCompanyInfo', name, website)
    }    
}

const mutations = {
    updateUserPlan (state, userPlan) {
        state.userPlan = userPlan
    },
    updateUserInfo (state, name, email, phone, password) {
        state.user.name = name
        state.user.email = email
        state.user.phone = phone
        state.user.password = password
    },
    updateCompanyInfo (state, name, website) {
        state.company.name = name
        state.company.website = website
    }
}

export default {
    namespaced: true,
    UserPlan,
    state,
    getters,
    actions,
    mutations
}
