import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/termos/assinarTermos',
    component: Layout,
    children: [       
        {            
            path: '',
            component: () => import('@/components/termos/assinarTermos'),
            meta: {
                auth: true
            }
        }
    ]
}]
