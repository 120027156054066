<template>
    
    <li class="nav-item navbar-text navbar-search-box p-0 active flex-fill">
       <a href="#" class="nav-link d-block d-md-none" @click.prevent="toggleSearch" >
                <div ref="toggleSearchWrapper" class="navbar-icon rounded-lg" :class="{'active': searchBarCollapsed}"><i class="navbar-user-icon ion ion-ios-search p-2"></i></div>
            </a>
            <div ref="searchWrapper" class="w-lg-auto search-wrapper px-2 px-sm-0" :class="{'active': searchBarCollapsed}" align="center">
            <div>
                <i class="ion ion-ios-search navbar-icon align-middle"></i>
                <div class="flex-fill">
                    <input 
                        ref="inputSearch"
                        type="text" 
                        class="navbar-input-search bordered"
                        x-webkit-speech
                        placeholder="Busca rápida"
                        autocomplete="off"
                        v-model="value"
                        @input="debounceSearch($event.target.value)"
                        @keydown.down="onDown"
                        @keydown.up="onUp"
                        @keydown.enter="hit"
                        @keydown.esc="reset"
                        @focus="show"
                        @blur="hide" />
                    <div id="search-menu" class="dropdown-search dropdown-menu w-100" :class="{ 'd-block': (hasItems || searched) && showResult && value.length > 0 }" >
                        <perfect-scrollbar ref="scrollDiv" :options="{ wheelPropagation: true }" class="mobileBox shadow-sm" style="max-height: 350px">
                            
                            <b-dropdown-item  :ref="item.guid" class="custom-list-item position-relative d-flex" v-for="(item, index) in items"  :key="item.guid" @click="setActive(index);hit(item)" :class="activeClass(index)">
                                <!-- Ações -->
                                <i class="custom-list-item-icon mr-3 my-auto" :class="item.category|categoriaIcone" v-if="item.type === 0"></i>
                                <i class="action-icon" :class="item.actionType|acaoIcone" v-if="item.type === 0"></i>
                                <div class="custom-list-item-content w-100 d-block" v-if="item.type === 0">
                                    <h5>{{item.title}}</h5>
                                </div>
                                <!-- Pesquisa -->
                                <i class="custom-list-item-icon mr-3 my-auto" :class="item.category|categoriaIcone" v-if="item.type === 1"></i>
                                <div class="custom-list-item-content w-100 d-block" v-if="item.type === 1">
                                    <div v-html="getCategoriaNome(item.category)"></div>
                                    <h5 class="py-1">{{item.title}}</h5>
                                    <div class="small text-muted" v-show="item.fieldName !== null" >{{item.fieldName}}: <span v-html="highlight(index)"></span></div>
                                </div>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="itensFilterType1.length === 0" class="custom-list-item position-relative d-flex" @click="openMovidesk()">
                               <i class="custom-list-item-icon mr-3 my-auto ion ion-ios-help-circle-outline"></i>
                                <div class="custom-list-item-content w-100 d-block">
                                    <h5 class="m-0" style="color: #1e70cd" v-if="termosAjuda()">Central de Ajuda</h5>
                                    <h5 class="m-0" style="color: #1e70cd" v-else>Pesquisar "{{value}}" na central de ajuda</h5>
                                </div>
                            </b-dropdown-item>
                        </perfect-scrollbar>
                    </div >
                    
                    <transition name="fade">
                        <div v-if="loading" class="search-loading-icon">
                            <i class="fas fa-spinner fa-spin"></i>
                        </div>
                    </transition>
                    
                </div>
            </div>
            </div>
        
    </li>
    
</template>

<style lang="scss">
    @import "../../../style/common.scss";
    .search-loading-icon{
        position: absolute;
        top:50%;
        right: 22px;
        margin-top: -12px;
        color:$Grey_7;
        
        > i {
            font-size: 24px;
            color:$Grey_5;
        }
    }
    .action-icon{
        position: absolute;
        bottom: 10px;
        left: 20px;
        font-size: 8px;
        width: 12px;
        height: 12px;
        background-color: $LightGreen_2;
        text-align: center;
        line-height: 12px;
        border-radius: 50%;
        color: #fff;
    }
    .navbar-search-box{
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .search-wrapper{
        min-width: 50%;
    }
    .search-wrapper > div{
        max-width: 640px;
        position: relative;
    }

    .dropdown-search a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-nav .navbar-search-box .navbar-input-search{
        background-color: $Grey_3 !important;
        padding: 0px 5px 0px 70px;
        min-width: 60%;
        width:100%;
        border:none;
        line-height: 42px;
        font-size: 20px;
        color: $Grey_6;
        
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
    }

    @media (max-width: 768px) {
        .navbar-search-box{
            -webkit-box-pack: end !important;
            -ms-flex-pack: end !important;
            justify-content: flex-end !important;
        }
        .search-wrapper{
            position:fixed;
            top:50px;
            left:0px;
            right:0px;
            z-index: 1;
            background-color: #fff;
            overflow: hidden;
            height: 0px;
            
            -webkit-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            -o-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }
        .navbar-trial .search-wrapper{
            top:77px;
        }
        .search-wrapper > div {
            padding: 0.5rem 0;
        }
        .search-wrapper.active{
            height:60px;
            
        }
        .navbar-search-box .search-wrapper .dropdown-search{
            left: 0px !important;
            right: 0px !important;
            position: fixed !important;
            top: 126px !important;
        }
        .navbar-nav .navbar-search-box .navbar-input-search{
            font-size: 16px !important;
            padding: 0px 15px 0px 15px;
        }
        .search-wrapper .navbar-icon {
            display: none;
        }
    }

    .navbar-nav .navbar-search-box .search-wrapper .navbar-icon {
        position: absolute;
        top:50%;
        left: 22px;
        margin-top: -12px;
        color:$Grey_7;
        font-size: 24px;
    }

    #search-menu {
        position: absolute;
        min-width: 30%;
        top: 46px;
        border-top: none;
        left: auto;
        right: auto;
    }

    #search-menu li.active a {
        background-color: rgba(24, 28, 33, 0.03);
    }    

    @media (max-width: 991px) {
        #search-menu {
            left: 0
        }
    }
    .highlight {
        font-weight: bold
    }

    .action {
        color: #339AFF
    }

    .result-typeahead {
        list-style: none;
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    .result-typeahead li {
        cursor: pointer;
        padding: 10px 15px 10px 15px;
        border-bottom: solid 1px #e0e0e0;  
    }

    .text-white {
        color: #fff
    }

    .dot {
        height: 30px;
        width: 30px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
    }

    .dot-letter {
        text-align: center;
        font-size: 20px;
        color: #fff;
    }
    .categoria-1{ color:#0C39AB;}
    .categoria-2{ color:#E88472;}
    .categoria-3{ color:#EA9E2D;}
    .categoria-4{ color:#2CC1A6;}
    .categoria-5{ color:#6732D2;}
</style>
<script>

import VueTypeahead from 'vue-typeahead'
import eventBus from '../../../eventBus/eventBus'
import PerfectScrollbar from '@/vendor/libs/perfect-scrollbar/PerfectScrollbar'

export default {
    extends: VueTypeahead,
    name: 'search-bar',

    data: () => ({
        value: '',
        src: `${process.env.VUE_APP_API_SERVER}/search/getMatch`,
        limit: 15,
        minChars: 3,
        search_timeout: null,
        showResult: false,
        searched: false,
        bugMobileInputFlag: false,
        searchBarCollapsed: false
    }),
    created: function () {
        eventBus.$on('resetInput', (resp) => {
            this.bugMobileInputFlag = true
        })
    },
    components: {
        PerfectScrollbar
    },
    computed: {
        itensFilterType0 () {
            return this.items.filter(function (val) {
                return val.type === 0
            })
        },
        itensFilterType1 () {
            return this.items.filter(function (val) {
                return val.type === 1
            })
        }
    },
    filters: {
        acaoIcone: function (value) {
            // enum TipoAcaoPesquisa
            // Inclusao = 1,
            // Pesquisa = 2
            switch (value) {
                case 1: return 'fas fa-plus'
                case 2: return 'fas fa-bars'
                default:
                    return ''
            }
        },
        categoriaIcone: function (value) {
            // enum CategoriaPesquisa
            // Usuario = 1,
            // Segurado = 2,
            // Sinistro = 3,
            // Proposta = 4
            // Orcamento = 5
            switch (value) {
                case 1: return 'lnr lnr-briefcase'
                case 2: return 'lnr lnr-user'
                case 3: return 'lnr lnr-warning'
                case 4: return 'lnr lnr-file-empty'
                case 5: return 'ion ion-md-calculator'
                case 6: return 'ion ion-md-calculator'
                default:
                    return 'lnr lnr-chevron-right'
            }
        }
    },
    methods: {
        onDown () {
            this.down()
            if (this.current > -1) {
                this.$refs[this.items[this.current].guid][0].$el.children[0].focus()
            }
            this.$refs.inputSearch.focus()
        },
        onUp () {
            this.up()
            if (this.current > -1) {
                this.$refs[this.items[this.current].guid][0].$el.children[0].focus()
            }
            this.$refs.inputSearch.focus()
        },
        debounceSearch (val) {
            if (this.search_timeout) {
                clearTimeout(this.search_timeout)
            }
            let vm = this
            this.search_timeout = setTimeout(function () {
                vm.value = val
                if (vm.bugMobileInputFlag === false) {
                    vm.updateQuery()
                    if (vm.value.length > 0) {
                        vm.searched = true
                    } else {
                        vm.searched = false
                    }
                    vm.showResult = true
                } else {
                    vm.resetInput()
                }
                vm.bugMobileInputFlag = false
            }, 1000)
        },
        highlight (index) {
            if (!this.value) {
                return this.items[index].fieldValue
            }
            
            return this.removeAccents(this.items[index].fieldValue).replace(new RegExp(this.removeAccents(this.value), 'gi'), match => {
                return '<span class=\'highlight\'>' + match + '</span>'
            })
        },
        highlightActions (index) {
            if (!this.value) {
                return this.items[index].title
            }
            return this.items[index].title.replace(new RegExp(this.value, 'gi'), match => {
                return '<span class=\'highlight\'>' + match + '</span>'
            })
        },
        termosAjuda: function () {
            return ['suporte', 'ajuda'].filter(x => x.match(new RegExp(this.value, 'gi')) != null).length > 0
        },
        updateQuery () {
            this.items = []
            this.query = this.value
            this.update()
        },
        onHit (item) {
            this.searchBarCollapsed = false
            this.resetInput()
            this.$router.push(this.setURL(item.url))
        },
        resetInput () {
            this.value = ''
            this.reset()
        },
        show () {
            this.showResult = true
        },
        hide (e) {
            e = e || window.event
            var elem = e.relatedTarget
            if (elem !== null && elem.hasAttribute('role') && elem.attributes['role'].value === 'menuitem') {
                return
            }
            this.searched = false
            this.showResult = false
        },
        getCategoriaNome: function (value) {
            // enum CategoriaPesquisa
            // Usuario = 1,
            // Segurado = 2,
            // Sinistro = 3,
            // Proposta = 4,
            // Orçamento = 5
            switch (value) {
                case 1: return '<small class="categoria-1">Usuário</small>'
                case 2: return '<small class="categoria-2">Segurado</small>'
                case 3: return '<small class="categoria-3">Sinistro</small>'
                case 4: return '<small class="categoria-4">Proposta</small>'
                case 5: return '<small class="categoria-5">Orçamento</small>'
                case 6: return '<small class="categoria-5">Cotação</small>'
                default:
                    return ''
            }
        },
        openMovidesk () {
            window.open(`https://ajuda.segfy.com/knowledge/Search?q=${this.value}`, '_blank')
        },
        toggleSearch () {
            if (this.searchBarCollapsed === false) {
                this.resetInput()
            }
            this.searchBarCollapsed = !this.searchBarCollapsed
            if (this.searchBarCollapsed) {
                setTimeout(() => {
                    this.$refs.inputSearch.focus()
                }, 200) 
            }
        }
    }
}

</script>
