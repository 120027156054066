import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/notificacoes',
    component: Layout,
    children: [
        {
            name: 'notificacao-painel',
            path: '',
            component: () => import('@/components/notificacao/mural'),
            meta: {
                auth: true
            }
        }
    ]
}]
