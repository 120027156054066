import Layout from '@/layout/onboarding/layout'

export default [{
    path: '/onboarding',
    component: Layout,
    children: [
        {
            path: '',
            name: 'onboarding-chooseplan',
            component: () => import('@/components/onboarding/choosePlan'),
            meta: {
                auth: false
            }
        },
        {
            path: 'userInfo',
            name: 'onboarding-userInfo',
            component: () => import('@/components/onboarding/userInfo'),
            meta: {
                auth: false
            }
        },
        {
            path: 'companyInfo',
            name: 'onboarding-companyInfo',
            component: () => import('@/components/onboarding/companyInfo'),
            meta: {
                auth: false
            }
        },
        {
            path: 'insuranceCompanies',
            name: 'onboarding-insuranceCompanies',
            component: () => import('@/components/onboarding/insuranceCompanies'),
            meta: {
                auth: false
            }
        }
    ]
}]
