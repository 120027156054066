import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/marketing',
    component: Layout,
    children: [
        {
            name: 'marketing-emailMarketing',
            path: 'emailMarketing',
            component: () => import('@/components/marketing/emailMarketing'),
            meta: {
                auth: true
            }
        },
        {
            name: 'marketing-novaCampanha',
            path: 'novaCampanha',
            component: () => import('@/components/marketing/novaCampanha'),
            meta: {
                auth: true
            }
        }
    ]
}]
