import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/',
    component: Layout,
    children: [
        {
            name: 'tabela-automatizacao',
            path: 'tabelaAutomatizacao',
            component: () => import('@/components/tabelaAutomatizacao/tabela'),
            meta: {
                auth: true
           }
        }   
        ]
    }
]
