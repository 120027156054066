import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/buscas',
    component: Layout,
    children: [
        {
            name: 'busca-extrato',
            path: 'extratos',
            component: () => import('@/components/buscas/extratos/busca'),
            meta: {
                auth: true,
                needsPermission: 'acessoRecebimento' // a permissão de Recebimento se aplica para a busca de extratos
            }
        },
        {
            name: 'importar-extrato',
            path: 'extratos/importar',
            component: () => import('@/components/buscas/extratos/importar'),
            meta: {
                auth: true
            }
        },
        {
            name: 'busca-parcela',
            path: 'parcelas',
            component: () => import('@/components/buscas/parcelas/busca'),
            meta: {
                auth: true,
                needsPermission: 'acessoParcelaAtrasada'
            }
        },
        {
            name: 'busca-propostas',
            path: 'propostas',
            component: () => import('@/components/buscas/propostas/buscaDocs'),
            meta: {
                auth: true,
                needsPermission: 'acessoBuscaDocumentos'
            }
        },
        {
            name: 'busca-apolices',
            path: 'apolices',
            component: () => import('@/components/buscas/apolices/buscaDocs'),
            meta: {
                auth: true,
                needsPermission: 'acessoBuscaDocumentos'
            }
        },
        {
            name: 'busca-docs',
            path: 'docs',
            component: () => import('@/components/buscas/propostas/busca'),
            meta: {
                auth: true
            }
        },
        {
            name: 'importar-documentos',
            path: 'documentos/importar',
            component: () => import('@/components/buscas/propostas/importar'),
            meta: {
                auth: true
            }
        }
    ]
}]
