var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    { attrs: { id: "modal-plugin", size: "lg" } },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm.status === 1
          ? _c(
              "div",
              {
                staticClass: "d-flex justify-content-between align-items-center"
              },
              [
                _c("b-form-select", {
                  attrs: { plain: "", options: _vm.options, size: "md" },
                  on: { change: _vm.setDelay },
                  model: {
                    value: _vm.valor,
                    callback: function($$v) {
                      _vm.valor = $$v
                    },
                    expression: "valor"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.status === 1
          ? _c("div", { key: "status1" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v("Seu navegador ainda não possui a Extensão Segfy!")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center my-3" }, [
                _vm._v(
                  "Instale agora mesmo, e nossos robôs irão agilizar para você "
                ),
                _c("br"),
                _vm._v(" vários processos dentro dos portais das seguradoras.")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "d-block mx-auto",
                attrs: { src: "../img/tutorial/tutorial-plugin.gif" }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-100 d-block mt-5" },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "d-block mx-auto",
                      attrs: { variant: "primary", size: "lg" },
                      on: { click: _vm.install }
                    },
                    [_vm._v("Instalar extensão")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-100 d-block" }, [
                _c(
                  "a",
                  {
                    staticClass: "d-block mx-auto mt-3 text-center",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.status = 3
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Dúvidas de como instalar? Veja como é rápido e fácil."
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.status === 2
          ? _c("div", { key: "status2" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v("Já terminou a instalação?")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "Caso tenha instalado, atualize a página para poder usar o plugin."
                )
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "d-block mx-auto",
                attrs: { src: "../img/tutorial/tutorial-plugin-refresh.jpg" }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-100 d-block mt-5" },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "d-block mx-auto",
                      attrs: { variant: "primary", size: "lg" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.refreshPage($event)
                        }
                      }
                    },
                    [_vm._v("Atualizar a página")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-100 d-block" }, [
                _c(
                  "a",
                  {
                    staticClass: "d-block mx-auto mt-3 text-center",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.status = 3
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Dúvidas de como instalar? Veja como é rápido e fácil."
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.status === 3
          ? _c(
              "div",
              { key: "status3" },
              [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v("Tutorial de instalação")
                ]),
                _vm._v(" "),
                _c(
                  "b-alert",
                  {
                    staticClass: "d-flex flex-column p-3 my-5",
                    attrs: { variant: "secondary", show: _vm.isChrome }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-npitems-center justify-content-center mr-2"
                          },
                          [_vm._v("1")]
                        ),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://chrome.google.com/webstore/detail/segfy-multic%C3%A1lculo/llckbbdajkkihfabigiedajkgofgmlgm"
                              }
                            },
                            [_vm._v(" Clique aqui")]
                          ),
                          _vm._v(
                            " - você será redirecionado para a Chrome Web Store "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-items-center justify-content-center mr-2"
                          },
                          [_vm._v("2")]
                        ),
                        _c(
                          "span",
                          [
                            _vm._v(" Clique no botão "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-chrome-1"
                                }
                              },
                              [
                                _vm._v("Usar no Chrome "),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-items-center justify-content-center mr-2"
                          },
                          [_vm._v("3")]
                        ),
                        _c(
                          "span",
                          [
                            _vm._v(" Clique em "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-chrome-2"
                                }
                              },
                              [
                                _vm._v("Adicionar extensão "),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            ),
                            _vm._v(" no pop-up")
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-alert",
                  {
                    staticClass: "d-flex flex-column p-3 my-5",
                    attrs: { variant: "secondary", show: _vm.isFirefox }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-items-center justify-content-center mr-2"
                          },
                          [_vm._v("1")]
                        ),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://multicalculo.segfy.com/extension/multicalculo-extension?version=4.0.4"
                              }
                            },
                            [_vm._v(" Clique aqui")]
                          ),
                          _vm._v(" - para baixar o plugin ")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-items-center justify-content-center mr-2"
                          },
                          [_vm._v("2")]
                        ),
                        _c(
                          "span",
                          [
                            _vm._v(" Clique em "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-firefox-1"
                                }
                              },
                              [
                                _vm._v(" Salvar arquivo "),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-items-center justify-content-center mr-2"
                          },
                          [_vm._v("3")]
                        ),
                        _c(
                          "span",
                          [
                            _vm._v(" No topo direito clique no  "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-firefox-2"
                                }
                              },
                              [
                                _vm._v(" ícone de downloads "),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            ),
                            _vm._v(" e "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-firefox-3"
                                }
                              },
                              [
                                _vm._v(" abra o arquivo"),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-items-center p-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle d-flex align-items-center justify-content-center mr-2"
                          },
                          [_vm._v("3")]
                        ),
                        _c(
                          "span",
                          [
                            _vm._v(" Clique em "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-firefox-4"
                                }
                              },
                              [
                                _vm._v(" adicionar "),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            ),
                            _vm._v(", de "),
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  variant: "outline-info",
                                  id: "tutorial-plugin-firefox-5"
                                }
                              },
                              [
                                _vm._v(" check no box "),
                                _c("i", { staticClass: "fas fa-info-circle" })
                              ]
                            ),
                            _vm._v(' e então "ok, entendi".')
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-chrome-1",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-chrome-1.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-chrome-2",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-chrome-2.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-firefox-1",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-firefox-1.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-firefox-2",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-firefox-2.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-firefox-3",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-firefox-3.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-firefox-4",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-firefox-4.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    staticClass: "popover-secondary",
                    attrs: {
                      target: "tutorial-plugin-firefox-5",
                      triggers: "hover focus",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: "../img/tutorial/tutorial-plugin-firefox-5.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "text-center my-5" }, [
                  _vm._v(
                    "Instalou? Agora é só atualizar a página para usar o nosso plugin"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-100 d-block" },
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "d-block mx-auto",
                        attrs: { variant: "primary", size: "lg" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.refreshPage($event)
                          }
                        }
                      },
                      [_vm._v("Atualizar a página")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "w-100 d-block" }, [
                  _c(
                    "a",
                    {
                      staticClass: "d-block mx-auto mt-3",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.status = 1
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "lnr lnr-chevron-left" }),
                      _vm._v(" Voltar")
                    ]
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "w-100 modal-footer-plugin",
        attrs: { slot: "modal-footer" },
        slot: "modal-footer"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }