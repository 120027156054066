import layoutHelpers from '@/layout/base/helpers.js'
import moment from 'moment'

// let cipher = salt => {
//   let textToChars = text => text.split('').map(c => c.charCodeAt(0))
//   let byteHex = n => ('0' + Number(n).toString(16)).substr(-2)
//   let applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)    

//   return text => text.split('')
//       .map(textToChars)
//       .map(applySaltToChar)
//       .map(byteHex)
//       .join('')
// }

export default function () {
  return {
    // Base url
    baseUrl: process.env.BASE_URL,
    baseUrlLoginSSO: `${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`,

    SMsize: '768px',
    isXS: false,
    isSM: false,
    isMD: true,
    isLG: false,
    isXL: false,
    isXXL: false,
    screenSize: 'md',
    isSegfyer: false,
    forcelogoff: false,
    // Layout helpers
    layoutHelpers,
    get autoFillClass () {
      return 'input-dirty'
    },
    // Check for RTL layout
    get isRTL () {
      return document.documentElement.getAttribute('dir') === 'rtl' ||
             document.body.getAttribute('dir') === 'rtl'
    },

    // Check if IE
    get isIEMode () {
      return typeof document['documentMode'] === 'number'
    },

    // Check if IE10
    get isIE10Mode () {
      return this.isIEMode && document['documentMode'] === 10
    },

    // Layout navbar color
    get layoutNavbarBg () {
      return 'navbar-theme'
    },

    get layoutNavbarSegfyerBg () {
      return 'navbar-theme navbar-segfyer'
    },

    // Layout sidenav color
    get layoutSidenavBg () {
      return 'sidenav-theme'
    },

    // Layout footer color
    get layoutFooterBg () {
      return 'footer-theme'
    },

    get isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    setURL (link) {
      return link + '#navr=' + this.uid().split('-')[0]
    },

    uid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0
        let v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },

    removeAccents (text) {
        if (text === null) {
          return ''
        }

        text = text.toLowerCase()
        text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
        text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
        text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
        text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
        text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
        text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
        return text
    },

    arrayRefresh (array) {
      if (array === null || array.length === 0) return
      array.push(null)
      array.splice(array.length - 1, 1)
    },

    enumerate (array) {
      array.forEach((e, i) => {
        if (typeof e === 'object') {
            e['_id'] = i + 1
        }
      })
    },

    validEmail (email) {
      // eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      return re.test(email)
    },

    hasWhitespace (text) {
      // eslint-disable-next-line
      let re = /^(.*\s+.*)+$/
      return re.test(text)
    },

    formatMoneyBR (value) {
      return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },

    formatMoney (value, precision, decimalPoint, thousandPoint, prefix, sufix) {
        let vlIdx = 0
        let result = ''
        let p = precision
        let d = decimalPoint
        let t = thousandPoint
        if (!prefix) { prefix = '' }
        if (!sufix) { sufix = '' }

        if (typeof value === 'number') {
            value = parseFloat(value).toFixed(p)
        }
        value = value + ''
        var isNegative = value[0] === '-'
        value = value.replace(/\D/g, '')
        if (p > 0 && value.length <= p) {
            value = this.padLeft(value, p + 1, '0')
        }
        for (var i = value.length - 1; i >= 0; i--) {
            if (d && p > 0 && vlIdx === p) {
                result = d + result
            } else if (t && vlIdx > p && (vlIdx - p) % 3 === 0) {
                result = t + result
            }
            result = value[i] + result
            vlIdx++
        }
        return prefix + (isNegative ? '-' : '') + result + sufix
    },

    padLeft (val, n, char) {
      if (val >= n) return val
      var result = val
      for (var i = val.length; i < n; i++) {
          result = char + result
      }
      return result
    },

    padRight (val, n, char) {
        if (val.length >= n) return val
        var result = val
        for (var i = val.length; i < n; i++) {
            result = result + char
        }
        return result
    },

    // Animate scrollTop
    scrollTop (to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return
      const start = element.scrollTop
      const change = to - start
      const startDate = +new Date()

      // t = current time; b = start value; c = change in value; d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
      }

      const animateScroll = () => {
        const currentDate = +new Date()
        const currentTime = currentDate - startDate
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll)
        } else {
          element.scrollTop = to
        }
      }

      animateScroll()
    },

    scrollToElement (element, parent, duration = 0, margin = 0) {
      let scrollTo = element.offsetTop - parent.offsetHeight
      scrollTo = scrollTo < 0 ? 0 : scrollTo + margin
      if (scrollTo !== 0) {
        this.scrollTop(scrollTo, duration, parent)
      }
    },

    scrollToTag (element, parent, duration = 0, margin = 0) {
      let scrollTo = element.offsetTop + margin
      this.scrollTop(scrollTo, duration, parent)
    },

    logoutLegado (forcelogoff) {
      // Solicita logout no sistema legado - depois de limpar os dados do usuário logado
      var iframe = document.createElement('iframe')
      iframe.setAttribute('src', `${process.env.VUE_APP_LEGACY_SERVER.replace('{0}', this.$store.state.auth.server)}/Autenticacao?logout=1&forcelogoff=` + forcelogoff)
      iframe.classList.add('hiddenframe')
      iframe.addEventListener('load', () => {
          iframe.remove()
      }, false)
      document.body.append(iframe)
    },

    cryptChannel (text) {
      return btoa(text).replace(new RegExp('=', 'g'), '')
    },

    toDateEN (value) {
      if (value) return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      return null
    },

    toDateBR (value) {
      if (value) return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      return null
    },

    toDateTimeEN (value) {
      if (value) return moment(value, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      return null
    },

    toCamel (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },

    isArray (a) {
      return Array.isArray(a)
    },

    isObject (o) {
      return o === Object(o) && !this.isArray(o) && typeof o !== 'function'
    },

    objectToCamel (o) {
      if (this.isObject(o)) {
        const n = {}
    
        Object.keys(o)
          .forEach((k) => {
            n[this.toCamel(k)] = this.objectToCamel(o[k])
          })
    
        return n
      } else if (this.isArray(o)) {
        return o.map((i) => {
          return this.objectToCamel(i)
        })
      }
    
      return o
    }
  }
}
