import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/financeiro',
    component: Layout,
    children: [
        {
            name: 'financeiro-recebimentos',
            path: 'recebimentos',
            component: () => import('@/components/financeiro/recebimentos'),
            meta: {
                auth: true
            }
        },
        {
            name: 'financeiro-fluxo-de-caixa',
            path: 'fluxoCaixa',
            component: () => import('@/components/financeiro/fluxoCaixa'),
            meta: {
                auth: true
            }
        },
        {
            name: 'financeiro-parcelas',
            path: 'parcelas',
            component: () => import('@/components/financeiro/parcelas'),
            meta: {
                auth: true
            }
        },
        {
            name: 'financeiro-parcelas-atrasadas',
            path: 'parcelasAtrasadas',
            component: () => import('@/components/financeiro/parcelasAtrasadas'),
            meta: {
                auth: true
            }
        },
        {
            name: 'financeiro-faturas',
            path: 'faturas',
            component: () => import('@/components/financeiro/faturas'),
            meta: {
                auth: true
            }
        }
    ]
}]
