var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalbv",
        "hide-header-close": "",
        "hide-header": "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": ""
      }
    },
    [
      _c("div", { staticClass: "container-flex" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "text-title text-center" }, [
              _vm._v("Bem vindo ao Segfy!")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("img", {
              attrs: {
                src: "/img/modalTourGuide/bemvindo.svg",
                alt: "Bem vindo"
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 pt-3 pb-3" }, [
            _c(
              "span",
              { staticClass: "d-flex text-footer justify-content-center" },
              [_vm._v("O software mais poderoso para corretoras de seguros.")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "d-flex text-footer justify-content-center" },
              [
                _vm._v(
                  "Intuitivo, inovador e eficiente na gestão com controles precisos."
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center mt-3 mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn align-self-center buttonProsseguir",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.nextStep()
                }
              }
            },
            [_vm._v("Prosseguir")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }