<template>
    <div class="layout-wrapper layout-1" >
        <div class="layout-inner">
            <app-layout-navbar />

            <div class="layout-container">
                <app-layout-sidenav />

                <div class="layout-content">
                    <div class="router-transitions container-fluid d-flex align-items-stretch flex-grow-1 p-0">
                        <router-view :key="$route.fullPath" />
                    </div>

                    <!-- <app-layout-footer /> -->
                </div>
            </div>
        </div>
        <div class="layout-overlay" @click="closeSidenav"></div>
        <ModalPlugin ref="modalPlugin"></ModalPlugin>
        <ModalNovidades ref="modalNovidades" :mostrarSlide="mostrarSlide"></ModalNovidades>
    </div>
</template>

<style>
    .layout-wrapper{
        min-height: 400px;
    }
</style>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import LayoutNavbar from './layoutSegfy/layoutNavbar'
import LayoutSidenav from './layoutSegfy/layoutSidenav'
import LayoutFooter from './layoutSegfy/layoutFooter'
import ModalPlugin from './modalPlugin'
import ModalNovidades from './modalNovidades'
import Cookies from 'js-cookie'
import { logoffUser } from '../../components/auth/methods.js'

export default {
    name: 'app-layout-segfy',
    components: {
        'app-layout-navbar': LayoutNavbar,
        'app-layout-sidenav': LayoutSidenav,
        'app-layout-footer': LayoutFooter,
        ModalPlugin,
        ModalNovidades
    },
    data () {
         return {
             mostrarSlide: []
         }
    },
    mounted () {
        this.layoutHelpers.init()
        this.layoutHelpers.update()
        this.layoutHelpers.setAutoUpdate(true)
        this.mostrarSlide.push(moment(process.env.VUE_APP_DATE_NOVIDADES).add(0, 'days').isAfter(new Date()) && (this.$store.state.auth.isMC || this.$store.state.auth.isOrcamentacao))
        this.mostrarSlide.push(moment(process.env.VUE_APP_DATE_NOVIDADES).add(0, 'days').isAfter(new Date()) && this.$refs.modalNovidades.clienteEscolhidoNovidades(this.$store.state.auth.assinaturaId))

        if (this.verifyNews()) {
            this.mostrarSlide.forEach(x => {
                if (x) {
                    this.$refs.modalNovidades.openModal()
                    return false
                }
            })
        }

        let vm = this
        setTimeout(function () {
            if (vm.verifyInstall()) {
                vm.$refs.modalPlugin.openModal()
            }

            if (window.Vue.$store.state.auth.isAuth) {

            }
        }, 3000)
    },

    beforeDestroy () {
        this.layoutHelpers.destroy()
    },
    updated () {
        if (window.Vue.$store.state.auth.statusAssinatura === 'Reativada' && this.$route.query.realizada === 'true') {
             logoffUser().then(resp => {
          if (process.env.VUE_APP_FAYE !== 'local') {
            window.Vue.hub.connectionFaye.pub(
              'logoff',
              window.Vue.hub.connectionFaye.uid
            )
          }
          this.logoutLegado()
          this.forgetUser()
          window.Vue.onboarding.driver.reset()
          Cookies.set('vuex', 'true', {
                      expires: -1,
                      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
          })
          window.location.href = `${process.env.VUE_APP_SSO_FRONT}/logout?callback=${process.env.VUE_APP_SERVER}`
        })
            .catch()
        } else if (window.Vue.$store.state.auth.statusAssinatura === 'Reativada') {
            this.$router.push('/assinatura/reativar')
        }
        
        var assinado = false
        var termoAssinadoCookie = Cookies.get('termoAssinado')

        if (termoAssinadoCookie !== undefined && termoAssinadoCookie !== null) {
            assinado = termoAssinadoCookie.toLowerCase() === 'true'

            if (!assinado) {
                this.$router.push('/termos/assinarTermos')
            }
        }
    },
    methods: {
        verifyNews () {
            let inicioAssinatura = moment(moment().add(-1, 'days').format('YYYY-MM-DD'))
            if (this.$store.state.auth.dataAssinatura != null) {
                inicioAssinatura = moment(this.$store.state.auth.dataAssinatura)
            }

            let dataImplantacaoNovidades = moment(process.env.VUE_APP_DATE_NOVIDADES)
            let qtdeDias = dataImplantacaoNovidades.diff(inicioAssinatura, 'day')
            let dataLimiteMostrarNovidades = dataImplantacaoNovidades.clone().add(15, 'days')

            if (qtdeDias > 0 && dataLimiteMostrarNovidades.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
                if (!Cookies.get('modalnovidades_' + dataImplantacaoNovidades.format('YYYY-MM-DD'))) {
                    return true
                }
            }

            return false
        },

        verifyInstall () {
            if (this.isMobile) return false

            var element = document.getElementById('extension-version')
            if (element) {
                return false
            }

            var modalplugin = Cookies.get('modalplugin')
            if (modalplugin) {
                return false
            }

            return true
        },
        closeSidenav () {
            this.layoutHelpers.setCollapsed(true)
        },
        ...mapActions({
            forgetUser: 'auth/forgetUser',
            toggleSideMenu: 'settings/toggleSideMenu'
        })
    }
}
</script>
