import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/cancelamentoEmail',
    component: Layout,
    children: [
        {
            name: 'cancelamento-Email',
            path: '',
            component: () => import('@/components/cancelamentoEmail/cancelamentoEmail'),
            meta: {
                auth: true
            }
        }
    ]
}]
