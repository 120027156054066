
const state = {
    step: 0,
    loginConfigurado: false,
    fadeMenu: false
}

const mutations = {
    nextStep (state) {
        state.step++
    },
    setStep (state, value) {
        state.step = value
    },
    previousStep (state) {
        state.step--
    },
    addStep (state, value) {
        state.step += value
    },
    configLogin (state, value) {
        state.loginConfigurado = value
    },
    fadeMenu (state, value) {
        state.fadeMenu = value
    }
}

const getters = {
    step: state => state.step
}

const actions = {
    nextStep ({ commit }) {
        commit('nextStep')
    },
    previousStep ({ commit }) {
        commit('previousStep')
    },
    addStep ({ commit }) {
        commit('addStep')
    },
    configLogin ({ commit }) {
        commit('configLogin')
    },
    fadeMenu ({ commit }) {
        commit('fadeMenu')
    }
}
export default {
    namespaced: true,
    mutations,
    state,
    getters,
    actions
}
