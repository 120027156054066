<template>
  <b-nav-item-dropdown
    right
    no-caret
    ref="dropdownUser"
    class="dropdown-user mr-2"
    @show="showMenu"
    @hide="hideMenu"
  >
    <template slot="button-content">
      <div class="navbar-icon rounded-lg" :class="(menuAtivo ? 'active' : '')">
        <i class="navbar-user-icon lnr lnr-user p-2"></i>
      </div>
    </template>
    <div v-if="!isInTour">
      <div v-if="isSegfyer" class="custom-list-item d-flex p-4">
        <i class="custom-list-item-icon mr-3 my-auto lnr lnr-rocket"></i>
        <div class="custom-list-item-content">
          <h5 class="font-weight-bold py-0 text-body">{{nomeSegfyer}}</h5>
          <p class="text-muted mb-0 mt-1">{{emailSegfyer}}</p>
        </div>
      </div>
    </div>
    <hr class="my-0 mx-2" />
    <div v-if="!isInTour">
      <div class="custom-list-item d-flex p-4">
        <i class="custom-list-item-icon mr-3 my-auto lnr lnr-user"></i>
        <div class="custom-list-item-content">
          <h5 class="font-weight-bold py-0 text-body">{{userName}}</h5>
          <p class="text-muted mb-0 mt-1">{{userEmail}}</p>
          <router-link
            :to="this.setURL('/minhaConta/Perfil')"
            class="mt-2"
            @click.native="dropdownClick()"
          >Perfil</router-link>
        </div>
      </div>
    </div>
    <hr class="my-0 mx-2" />
    <div>
      <div v-if="!isInTour">
        <b-dropdown-item
          v-for="elem in menuItems"
          :key="elem.title"
          class="custom-list-item d-flex"
          @click.prevent="dropdownClick(elem.link)"
        >
          <i class="custom-list-item-icon mr-3 my-auto" :class="elem.icon"></i>
          <div class="custom-list-item-content">
            <h5>{{elem.title}}</h5>
          </div>
        </b-dropdown-item>
      </div>
      <!-- <b-dropdown-item v-if="isInTour" @click.stop="reiniciarTour" class="custom-list-item d-flex" dropleft>
        <i class="custom-list-item-icon mr-3 my-auto ion ion-ios-refresh"></i>
        <div class="custom-list-item-content">
          <h5>Reiniciar Tour</h5>
        </div>
      </b-dropdown-item> -->
      <b-dropdown-item @click="logoff()" class="custom-list-item d-flex">
        <i class="custom-list-item-icon mr-3 my-auto ion ion-ios-log-out"></i>
        <div class="custom-list-item-content">
          <h5>Sair</h5>
        </div>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<style lang="scss">
@import "../../../style/common.scss";
.dropdown-user {
  a {
    display: flex;
    align-items: center;
    pointer-events: all !important;
  }
}
.navbar-user-icon {
  font-size: 24px;
  color: $Grey_7;
  display: block;
}

.dropdown-user .dropdown-item:active {
  background-color: #e7e7e7 !important;
}
@media (max-width: 768px) {
  .dropdown-user .dropdown-toggle::after {
    display: none;
  }
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import { getMenuPerfil } from '../../../components/notificacao/methods.js'
import { logoffUser } from '../../../components/auth/methods.js'
import Cookies from 'js-cookie'

export default {
  name: 'user-options',

  props: {
    menuOpened: { type: Boolean, default: false }
  },

  data: function () {
    return {
      userName: '',
      userEmail: '',
      nomeSegfyer: '',
      emailSegfyer: '',
      userPicture: 'avatar.jpg',
      menuAtivo: false,
      menuItems: [],
      isInTour: false,
      isSegfyer: false
    }
  },
  computed: {
    ...mapState('newOnboarding', {
      stepOnboarding: 'step'
    })
  },
  watch: {
    stepOnboarding (val) {
      if (val > 0 && val < 17) {
        this.isInTour = true
        return
      }
      this.isInTour = false
    }
  },
  mounted: function () {
    this.requestMenuItems()
    this.userName = window.Vue.$store.state.auth.username
    this.userEmail = window.Vue.$store.state.auth.email
    this.nomeSegfyer = window.Vue.$store.state.auth.nomeSegfyer
    this.emailSegfyer = window.Vue.$store.state.auth.emailSegfyer
    this.isSegfyer = window.Vue.$store.state.auth.segfyer
  },
  methods: {
    showMenu: function () {
      this.menuAtivo = true
    },

    hideMenu: function () {
      this.menuAtivo = false
    },

    requestMenuItems: function () {
      getMenuPerfil().then(resp => {
        if (resp.success) {
          this.menuItems = resp.data
        }
      })
    },

    logoff: function () {
      return logoffUser()
        .then(resp => {
          if (process.env.VUE_APP_FAYE !== 'local') {
            window.Vue.hub.connectionFaye.pub(
              'logoff',
              window.Vue.hub.connectionFaye.uid
            )
          }
          this.logoutLegado()
          this.forgetUser()
          window.Vue.onboarding.driver.reset()
          Cookies.set('vuex', 'true', {
                      expires: -1,
                      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
          })
          window.location.href = `${process.env.VUE_APP_SSO_FRONT}/logout?callback=${process.env.VUE_APP_SERVER}`
        })
        .catch()
    },

    dropdownClick (link) {
      this.$refs.dropdownUser.visible = false
      if (link) {
        if (link.indexOf('http') >= 0) {
          let win = window.open(link, '_blank')
          win.focus()
        } else {
          this.$router.push(this.setURL(link))
        }
      }
    },
    reiniciarTour () {
      window.Vue.onboarding.driver.setFadeMenu(false)
      let element = document.getElementById('menu-home')
      element.click()
      setTimeout(() => {
        window.Vue.onboarding.driver.setStep(1)
      }, 200)
    },

    ...mapActions({
      forgetUser: 'auth/forgetUser'
    })
  }
}
</script>
