export function CheckEmailValid (email) {
    return new RegExp('\\S+@\\S+\\.\\S').test(email)
}

export function getUrlParameter (name) {
  // Get the full URL
  let url = window.location.href

  // Extract the query string from the URL
  let queryString = url.split('?')[1]

  // Check if there is a query string
  if (queryString) {
    // Remove the leading question mark
    queryString = queryString.substring(1)

    // Split the query string into an array of key-value pairs
    let params = queryString.split('&')

    // Loop through the key-value pairs
    for (let i = 0; i < params.length; i++) {
      let param = params[i].split('=')
      let key = decodeURIComponent(param[0])
      let value = decodeURIComponent(param[1])

      // Check if the current key matches the parameter name
      if (key === name) {
        // Return the corresponding value
        return value
      }
    }
  }

  // Return null if the parameter is not found
  return null
}

export function isFirefox (userAgent) {
  return userAgent.indexOf('Firefox') !== -1
}
