<template>
    <b-modal id="modal-novidades" size="lg" @hidden="resetModal">
        <div slot="modal-title">
            <checkField label="Não exibir novamente" class="mb-0" :value="naoExibirNovamente" @input="setNaoExibirMais" />
        </div>

        <!-- div class="text-center">
            <h3>Atenção, corretor!</h3>
        </!-->

        <!-- div class="px-4" -->
        <div>
            <carousel :navigationEnabled="true" :perPage="1" navigationNextLabel="<i class='ion ion-ios-arrow-forward'></i>" navigationPrevLabel="<i class='ion ion-ios-arrow-back'></i>" paginationActiveColor="#FF5028">
                <slide v-if="mostrarSlide[0]">
                    <div class="text-center">
                        <div class="w-100 d-block">
                            <p class="text-dark">A partir de 01/09/2020 as cotações da <strong>HDI (automóvel)</strong> serão feitas exclusivamente por webservice! <br />
                            Para que você possa usufruir dessa novidade você deve:</p>
                        </div>
                        <div class="w-100 d-block mt-2">
                            <img src='/img/modalnovidades/configuracao_HDI.png' style='max-width: 100%;'/>
                        </div>
                        <div class="w-100 d-block">
                            <p class="text-dark"><br />
                            Ao acessar a tela de logins Seguradoras será necessário <strong>clicar na Seguradora HDI</strong> e preencher o <strong>código Sucursal e Susep</strong>.</p>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
        <div slot="modal-footer" style="display:none; !important">
        </div>   
    </b-modal>
</template>

<style lang="scss">
    @import "../../vendor/styles/_appwork/_include";
    @import "../../vendor/styles/_appwork/_variables";

    .imagem-teste {
        max-width: 100%;
        height: 284px;
    }

    .imagem-teste img {
        display: block;
    }

    .imagem-teste iframe {
        display: block;
        max-width: 80%;
    }

    #modal-novidades .modal-footer {
        display: none;
    }

    #modal-novidades .modal-body {
        padding-top: 0px;
    }

    #modal-novidades .custom-control-label::before{
        top:0.1rem !important;
    }
    #modal-novidades .custom-control-label{
        font-weight: normal;
        font-size: 14px;
    }

    #modal-novidades .main-image > img{
        max-width: 35rem;
        padding: 2rem;
    }
    #modal-novidades .VueCarousel-navigation-button{
        color: $dark;
        font-size: 28px;
    }
    #modal-novidades .VueCarousel-dot-container {
        margin-top:0px !important;
    }
    #modal-novidades .VueCarousel-dot{
        margin-top:0px !important;
    }
    #modal-novidades footer {
        border-top:none;
    }
</style>

<script>
import moment from 'moment'
import Cookies from 'js-cookie'
import checkField from '../../components/common/checkField'

export default {
    name: 'modalNovidades',
    props: ['mostrarSlide'],
    data () {
        return {
            status: 1,
            videoUrl: 'https://www.youtube.com/embed/trdpj0u1ChQ',
            naoExibirNovamente: false,
            clientesEscolhidos: [
                1461
             ],
            link: 'https://segfy.movidesk.com/kb/pt-br/article/71811/novas-ferramentas-segfy-multicalculo-busca-de-extratos-de-comissao-parcelas-e-documentos?preview=true&revisionId=187573'
        }
    },
    mounted () {
        // Ajuste técnico elaborado para corrigir o bug do componente de VueCarousel que não renderizava o conteúdo no load
        setTimeout(function () {
            document.getElementsByClassName('VueCarousel-inner')[0].style.transform = 'translate(1px, 0px)'
        }, 100)
    },
    components: {
        checkField
    },
    methods: {
        openModal (options) {
            this.$bvModal.show('modal-novidades')
        },

        clienteEscolhidoNovidades (codigo) {
            var ok = this.clientesEscolhidos.find(function (x) {
                return x === codigo
            })
            
            return ok > 0
        },

        setNaoExibirMais (naoExibir) {
            this.naoExibirNovamente = naoExibir
            let dataImplantacaoNovidades = moment(process.env.VUE_APP_DATE_NOVIDADES)
            Cookies.set(('modalnovidades_' + dataImplantacaoNovidades.format('YYYY-MM-DD')), 'true', {
                expires: (naoExibir ? 365 : 0),
                domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
            })
        },
        closeModal (close) {
            this.$bvModal.hide('modal-novidades')
        },
        resetModal () {
            this.videoUrl = 'https://www.segfy.com/'
            this.setNaoExibirMais(this.naoExibirNovamente)
        },
        gotoLink () {
            var win = window.open(this.link, '_blank')
            win.focus()
        }
    }
}
</script>
