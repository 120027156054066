import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/suporte',
    component: Layout,
    children: [
        {
            name: 'suporte',
            path: '',
            component: () => import('@/components/suporte/webinar'),
            meta: {
                auth: true
            },
            beforeEnter (to, from, next) {
                var url = `${process.env.VUE_APP_API_SERVER}/suporte?token=${to.query.t}`
                let win = window.open(url, '_blank')
                if (!win || win.closed || typeof win.closed === 'undefined') { 
                    window.Vue.$noty.error('Não foi possível abrir a janela de suporte. Verifique o bloqueador de pop-up do seu navegador.')
                } else {
                    win.focus()
                }
                return false
            }
        },
        {
            name: 'webinar',
            path: 'webinar',
            component: () => import('@/components/suporte/webinar'),
            meta: {
                auth: true
            }
        }]
}]
