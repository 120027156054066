var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      ref: "dropdownAppsMenu",
      staticClass: "dropdown-user mr-2",
      attrs: { "no-caret": "" },
      on: { show: _vm.showMenu, hide: _vm.hideMenu }
    },
    [
      _c("template", { slot: "button-content" }, [
        _c(
          "div",
          {
            staticClass: "navbar-icon rounded-lg",
            class: _vm.menuAtivo ? "active" : ""
          },
          [_c("i", { staticClass: "navbar-user-icon fa fa-th p-2" })]
        )
      ]),
      _vm._v(" "),
      [
        _c("div", { staticClass: "frame-container" }, [
          _c("div", { staticClass: "frame-content ff" }, [
            _c("iframe", {
              ref: "iframe",
              attrs: {
                "data-hj-allow-iframe": "",
                src: _vm.candybox,
                frameborder: "no",
                allowtransparency: "true",
                id: "apps-menu"
              }
            })
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }