import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/sistema',
    component: Layout,
    children: [
        {
            path: 'atualizacao',
            component: () => import('@/components/sistema/atualizacaoSegfy'),
            meta: {
                auth: true
            } 
        }
    ]
}]
