var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidenav",
    {
      class: _vm.curClasses,
      staticStyle: { border: "none" },
      attrs: { orientation: "vertical" }
    },
    [
      _vm.fadeMenu
        ? _c("div", { ref: "fadeMenu", staticClass: "backgroundFade" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("block-ui", { attrs: { loading: _vm.menuLoading } }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.noMenuItems,
                  expression: "noMenuItems"
                }
              ]
            },
            [
              _c(
                "sidenav-header",
                {
                  staticClass: "font-weight-bold",
                  staticStyle: { color: "white" }
                },
                [_vm._v("Nenhum menu disponível")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "perfect-scrollbar",
            {
              ref: "scrollDiv",
              staticClass: "mobileBox",
              attrs: { options: { wheelPropagation: true }, id: "scrollDiv" }
            },
            [
              _c("div", { staticClass: "mobileBox" }, [
                _c(
                  "div",
                  { staticClass: "sidenav-inner" },
                  _vm._l(_vm.sideMenuItems, function(elem, index) {
                    return _c(
                      "div",
                      {
                        key: elem.title,
                        staticClass: "w-100 sidenav-item-wrapper",
                        class: elem.destacado ? "menu-destacado" : "menu-padrao"
                      },
                      [
                        elem.subitems === null
                          ? _c(
                              "div",
                              {
                                staticClass: "sidenav-item",
                                class: {
                                  active: _vm.isActive(null, elem.link, index)
                                },
                                attrs: {
                                  id:
                                    "div-menu-" +
                                    elem.title
                                      .replace(/\s+/g, "-")
                                      .toLowerCase()
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "sidenav-link",
                                    attrs: {
                                      id:
                                        "menu-" +
                                        elem.title
                                          .replace(/\s+/g, "-")
                                          .toLowerCase(),
                                      href: elem.link
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        _vm.onClick(elem)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "sidenav-icon",
                                      class: elem.icon
                                    }),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [_vm._v(_vm._s(elem.title))]),
                                      _vm._v(" "),
                                      elem.destacado
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(elem.title.split(" ")[0])
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    elem.title === "Tarefas" &&
                                    _vm.qtdTarefas > 0
                                      ? _c(
                                          "b-badge",
                                          {
                                            staticClass: "ml-auto",
                                            attrs: { variant: "segfy" }
                                          },
                                          [_vm._v(_vm._s(_vm.qtdTarefas))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "sidenav-item group-item",
                                class: {
                                  "active open": _vm.isActive(
                                    elem.rota,
                                    null,
                                    index,
                                    true
                                  )
                                },
                                attrs: {
                                  name: elem.rota,
                                  id:
                                    "div-menu-" +
                                    elem.title
                                      .replace(/\s+/g, "-")
                                      .toLowerCase()
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "sidenav-link sidenav-toggle",
                                    attrs: {
                                      id:
                                        "menu-" +
                                        elem.title
                                          .replace(/\s+/g, "-")
                                          .toLowerCase(),
                                      href: "javascript:void(0)"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.onParentMenuClick($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "sidenav-icon",
                                      class: elem.icon
                                    }),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [_vm._v(_vm._s(elem.title))])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "sidenav-menu d-flex" },
                                  _vm._l(elem.subitems, function(subitem) {
                                    return _c(
                                      "div",
                                      {
                                        key: subitem.title,
                                        staticClass: "sidenav-item",
                                        class: {
                                          active: _vm.isActive(
                                            elem.rota,
                                            subitem.link,
                                            index
                                          )
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "sidenav-link",
                                            attrs: {
                                              id:
                                                "submenu-" +
                                                subitem.title
                                                  .replace(/\s+/g, "-")
                                                  .toLowerCase(),
                                              href: subitem.link
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                _vm.onClick(subitem)
                                              }
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(subitem.title))
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("ModalContratarMenu", { ref: "modalContratarMenu" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }