<template>
    <div class="navbar container-fluid d-flex justify-content-center">
        <img src='/img/logos/logo-segfy2.png' class='h-100 noselect'/>
    </div>
</template>

<style>
    .navbar {
        background-color: white;
        height: 70px
    }
</style>

<script>
export default {
    name: 'onboarding-navbar'
}
</script>
