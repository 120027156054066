var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalVideo",
        "hide-header-close": "",
        "hide-header": "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": ""
      }
    },
    [
      _c("div", { staticClass: "container-flex" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "text-title text-center" }, [
              _vm._v("Bem vindo ao Segfy!")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 pt-3 pb-3" }, [
            _c("span", { staticClass: "text-justify text-desc" }, [
              _vm._v(
                "Confira nosso video de introdução ao sistema, e entenda como funciona o Segfy:"
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("iframe", {
          attrs: {
            id: "ytplayer",
            type: "text/html",
            width: "720",
            height: "540",
            src:
              "https://www.youtube.com/embed/rEPbv2PSwDI?autoplay=0&cc_load_policy=1&disablekb=1&fs=0&color=white",
            frameborder: "0",
            allowfullscreen: ""
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center mt-3 mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn align-self-center buttonProsseguir",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.nextStep()
                }
              }
            },
            [_vm._v("Prosseguir")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }