<template>
    <div ref="rootvuereact"></div>
</template>

<script>
import React from 'react'
import ReactDOM from 'react-dom'
import NotificationFront from '@segfy/notifications-front'

export default {
    name: 'NofityWrapper',
    props: {
        props: {
            type: Object,
            default: () => ({})
        }
    },
    mounted () {
        ReactDOM.render(React.createElement(NotificationFront, { system: 'upfy', notificationApi: process.env.VUE_APP_NOTIFICATIONS_API, isMenu: true }), this.$refs.rootvuereact)
    }
}
</script>
