var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isXS === false && _vm.isSM === false
    ? _c(
        "b-nav-item-dropdown",
        {
          ref: "dropdown-webinars",
          staticClass: "dropdown-webinars mr-1",
          attrs: { right: "", "no-caret": "" },
          on: { show: _vm.showMenu, hide: _vm.hideMenu }
        },
        [
          _c("template", { slot: "button-content" }, [
            _c(
              "div",
              {
                staticClass: "navbar-icon",
                class: _vm.menuAtivo ? "active" : ""
              },
              [
                _vm.live === true
                  ? _c("label", { staticClass: "text-webinar-live" }, [
                      _vm._v("LIVE")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "fas fa-tv p-2" })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "webinars-list" },
            [
              _c(
                "b-list-group",
                { attrs: { flush: "" } },
                [
                  _vm.webinar !== null
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-list-item d-flex mx-2",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.goToWebinar(_vm.webinar)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "custom-list-item-content w-100 d-block"
                            },
                            [
                              _vm.live === true
                                ? _c(
                                    "h6",
                                    { staticClass: "text-body text-bold mb-1" },
                                    [_vm._v("Webinar:")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.live === false
                                ? _c(
                                    "h6",
                                    { staticClass: "text-body text-bold mb-1" },
                                    [_vm._v("Próximo Webinar:")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-light text-nowrap" },
                                [_vm._v(_vm._s(_vm.timeToWebinar))]
                              ),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(_vm.webinar.titulo))])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-list-group-item", { staticClass: "p-0" }, [
                    _c(
                      "a",
                      {
                        staticClass: "d-block bg-white text-center small p-3",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.showAllWebinars()
                          }
                        }
                      },
                      [_vm._v("Exibir todas as webinars")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }