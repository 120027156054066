import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/filtros',
    component: Layout,
    children: [
        {
            name: 'filtros-personalizados',
            path: 'novo',
            alias: '',
            component: () => import('@/components/filtros/filtrosPersonalizados'),
            meta: {
                auth: true
            }
        }
    ]
}]
