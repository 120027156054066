import store from './store'
import Driverjs from 'driver.js'

let instance = null

const driver = {
    start: function () {
        // if (instance === null) {
        //     instance = new Driverjs({
        //         className: 'guidePopover',
        //         allowClose: false,
        //         animate: false,
        //         keyboardControl: false,
        //         onDeselected: (el) => {
        //             let element = el.getNode()
        //             // Total de passos - 1 
        //             // Isso acontece pq o menu ajuda, como é o ultimo passo do fluxo, não precisa desativar seu pointer event ao fechar a aba
        //             if (this.getStep() <= 15) {
        //                 element.style.pointerEvents = 'none'
        //                 element.style.userSelect = 'none'
        //             } else {
        //                 element.style.pointerEvents = 'all'
        //                 element.style.userSelect = 'text'
        //             }
        //             if (element.id !== 'login-seguradora-boxes') {
        //                 this.nextStep()
        //             } else {
        //                 if (element.classList.contains('modalAberta')) {
        //                     this.addStep(0.5)
        //                 } else {
        //                     this.nextStep()
        //                 }
        //             }
        //         }
        //     })
        // }
    },
    reset: function () {
        // if (instance !== null) {
        //     instance.reset()
        // }
    },
    exec: function (id, popover, allowClick = true) {
        // if (!instance) {
        //     this.start()
        // }
        // instance.highlight({
        //     element: id,
        //     popover: popover
        // })
        // if (allowClick) {
        //     this.allowClick()
        // }
    },
    allowClick: function () {
        // if (instance !== null) {
        //     instance.getHighlightedElement().getNode().style.pointerEvents = 'all'
        //     instance.getHighlightedElement().getNode().style.userSelect = 'text'
        // }
    },
    getStep: function () {
        // return store.state.newOnboarding.step
    },
    getFadeMenu () {
        // return store.state.newOnboarding.fadeMenu
    },
    getLoginConfigurado: function () {
        // return store.state.newOnboarding.loginConfigurado
    },
    setFadeMenu (value) {
        // store.commit('newOnboarding/fadeMenu', value)
    },
    nextStep: function () {
        // store.commit('newOnboarding/nextStep')
    },
    addStep: function (value) {
        // store.commit('newOnboarding/addStep', value)
    },
    configLogin: function (value) {
        // store.commit('newOnboarding/configLogin', value)
    },
    setStep: function (value) {
        // store.commit('newOnboarding/setStep', value)
    }

}

window.onmessage = function (e) {
    // if (e.data === 'stepOnboarding=nextStep') {
    //     document.getElementById('scrollDiv').style.pointerEvents = 'all'
    //     document.getElementById('scrollDiv').style.userSelect = 'text'
    //     document.getElementById('navbarMenuTopoLayout').style.pointerEvents = 'all'
    //     document.getElementById('navbarMenuTopoLayout').style.userSelect = 'text'
    //     driver.setFadeMenu(false)
    //     driver.nextStep()
    // }
}

export default {
    driver
}
