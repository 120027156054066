var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { id: "modal-novidades", size: "lg" },
      on: { hidden: _vm.resetModal }
    },
    [
      _c(
        "div",
        { attrs: { slot: "modal-title" }, slot: "modal-title" },
        [
          _c("checkField", {
            staticClass: "mb-0",
            attrs: {
              label: "Não exibir novamente",
              value: _vm.naoExibirNovamente
            },
            on: { input: _vm.setNaoExibirMais }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "carousel",
            {
              attrs: {
                navigationEnabled: true,
                perPage: 1,
                navigationNextLabel:
                  "<i class='ion ion-ios-arrow-forward'></i>",
                navigationPrevLabel: "<i class='ion ion-ios-arrow-back'></i>",
                paginationActiveColor: "#FF5028"
              }
            },
            [
              _vm.mostrarSlide[0]
                ? _c("slide", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("div", { staticClass: "w-100 d-block" }, [
                        _c("p", { staticClass: "text-dark" }, [
                          _vm._v("A partir de 01/09/2020 as cotações da "),
                          _c("strong", [_vm._v("HDI (automóvel)")]),
                          _vm._v(
                            " serão feitas exclusivamente por webservice! "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                        Para que você possa usufruir dessa novidade você deve:"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-100 d-block mt-2" }, [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: {
                            src: "/img/modalnovidades/configuracao_HDI.png"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-100 d-block" }, [
                        _c("p", { staticClass: "text-dark" }, [
                          _c("br"),
                          _vm._v(
                            "\n                        Ao acessar a tela de logins Seguradoras será necessário "
                          ),
                          _c("strong", [_vm._v("clicar na Seguradora HDI")]),
                          _vm._v(" e preencher o "),
                          _c("strong", [_vm._v("código Sucursal e Susep")]),
                          _vm._v(".")
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticStyle: { display: "none" },
        attrs: { slot: "modal-footer" },
        slot: "modal-footer"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }