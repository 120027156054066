var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidenav-item",
      class: { active: _vm.active, disabled: _vm.disabled }
    },
    [
      _c(
        "a",
        {
          staticClass: "sidenav-link",
          class: _vm.linkClass,
          attrs: { href: _vm.href, target: _vm.target }
        },
        [
          _vm.icon
            ? _c("i", { staticClass: "sidenav-icon", class: _vm.icon })
            : _vm._e(),
          _vm._v(" "),
          _c("div", [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.badge
            ? _c("div", { staticClass: "pl-1 ml-auto" }, [
                _c("div", { staticClass: "badge", class: _vm.badgeClass }, [
                  _vm._v(_vm._s(_vm.badge))
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }