import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/sinistros',
    component: Layout,
    children: [
        {
            name: 'sinistros-painel',
            path: '',
            component: () => import('@/components/sinistros/painel'),
            meta: {
                auth: true
            }
        },
        {
            name: 'sinistros-painel-2',
            path: 'painel',
            component: () => import('@/components/sinistros/painel'),
            meta: {
                auth: true
            }
        },
        {
            name: 'sinistros-painel-sinistro',
            path: 'sinistro',
            component: () => import('@/components/sinistros/painel'),
            meta: {
                auth: true
            }
        },
        {
            name: 'sinistros-painel-sinistro-novo',
            path: 'novo',
            component: () => import('@/components/sinistros/novo'),
            meta: {
                auth: true
            }
        }
    ]
}]
