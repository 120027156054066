<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<!-- <style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style> -->
<style src="@/style/theme/theme-segfy.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style/style.scss" lang="scss"></style>

<script>
import LogRocket from 'logrocket'
export default {
    name: 'app',
    metaInfo: {
        title: 'Index',
        titleTemplate: 'Segfy.com - %s',
        htmlAttrs: {
            lang: 'pt-br'
        }
    },
    created () {
        LogRocket.init('gca7dn/segfy')
    },
    updated () {
        // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1)
    }
}
</script>
