function getAllWebinar () {
        return window.Vue.$http.get(`${process.env.VUE_APP_API_SERVER}/webinar/getAll`)
                    .then(resp => {
                        if (resp.data.success) {
                            return Promise.resolve(resp.data)
                        } else {
                            return Promise.reject(resp.data)
                        }
                    })
    }

    function subscribeWebinar (webinarId) {
        return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/webinar/subscribe/${webinarId}`)
                    .then(resp => {
                        if (resp.data.success) {
                            return Promise.resolve(resp.data)
                        } else {
                            return Promise.reject(resp.data)
                        }
                    })
    }

    function unsubscribeWebinar (listaId) {
        return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/webinar/unsubscribe/${listaId}`)
                    .then(resp => {
                        if (resp.data.success) {
                            return Promise.resolve(resp.data)
                        } else {
                            return Promise.reject(resp.data)
                        }
                    })
    }

    function getNextWebinar () {
        return window.Vue.$http.get(`${process.env.VUE_APP_API_SERVER}/webinar/getNext`)
                    .then(resp => {
                        if (resp.data.success) {
                            return Promise.resolve(resp.data)
                        } else {
                            return Promise.reject(resp.data)
                        }
                    })
    }

export { 
    getAllWebinar,
    subscribeWebinar,
    unsubscribeWebinar,
    getNextWebinar
}
