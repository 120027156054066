import Vue from 'vue'
import Axios from 'axios'

const instance = Axios.create({
    baseURL: process.env.VUE_APP_API_LOCATION
})

const instanceNoInterceptor = Axios.create({
    baseURL: process.env.VUE_APP_API_LOCATION
})

const instanceCookiesOnly = Axios.create({
    baseURL: process.env.VUE_APP_API_LOCATION
})

const Hfy = Axios.create({
    baseURL: process.env.VUE_APP_HFY
})

const automation = Axios.create({
    baseURL: process.env.VUE_APP_AUTOMATION
})

instance.defaults.headers.common.Accept = 'application/json'
instanceNoInterceptor.defaults.headers.common.Accept = 'application/json'
instanceCookiesOnly.defaults.headers.common.Accept = 'application/json'

// Axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION
// Axios.defaults.headers.common.Accept = 'application/json'

// Bind Axios to Vue and state
Vue.$http = instance
Vue.$httpNoInterceptor = instanceNoInterceptor
Vue.$httpAutomation = automation
Vue.$httpCookiesOnly = instanceCookiesOnly
// store.$http = Axios

Object.defineProperty(Vue.prototype, '$http', {
    get () {
        return instance
    }
})

Object.defineProperty(Vue.prototype, '$httpNoInterceptor', {
    get () {
        return instanceNoInterceptor
    }
})

Object.defineProperty(Vue.prototype, '$httpAutomation', {
    get () {
        return automation
    }
})

Object.defineProperty(Vue.prototype, '$httpCookiesOnly', {
    get () {
        return instanceCookiesOnly
    }
})

export default Axios

export {
    Hfy
}
