import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/chat',
    component: Layout,
    children: [
        {
            name: 'chat-chat',
            path: 'chat',
            component: () => import('@/components/chat/chat'),
            meta: {
                auth: true
            }
        }
    ]
}]
