<template>
   <b-form-group :invalid-feedback="feedbackOrigin" :description="descricao">
        <b-form-checkbox v-if="!switcher" :name="name" variant="success" v-model="valor" :state="stateOrigin" @change="eventoInput">{{label}}</b-form-checkbox>
        <label v-if="switcher" class="switcher">
            <input type="checkbox" class="switcher-input" :name="name" v-model="valor" :state="stateOrigin" @change="eventoInput(valor)">
            <span class="switcher-label">{{label}}</span>
            <span class="switcher-indicator">
                <span class="switcher-yes"></span>
                <span class="switcher-no"></span>
            </span>
        </label>
   </b-form-group>
</template>
<script>
export default {
   name: 'check-field',
   props: {
       switcher: {
           type: Boolean,
           required: false,
           default: false
       },
       value: {
           type: [ String, Boolean ],
           required: true,
           default: false
       },
       state: {
           type: Boolean,
           default: null
       },
       change: {
           type: Function,
           default: function () { }
       },
       feedback: {
           type: String,
           default: ''
       },
       label: {
           type: String,
           required: true
       },
       descricao: {
           type: String
       },
       name: {
           type: String,
           required: false
       }
   },
   data () {
       return {
        valor: this.value,
        stateOrigin: null,
        feedbackOrigin: ''
       }
   },
   methods: {
       eventoInput (e) {
           this.valor = e
           this.$emit('change', this.valor)
           this.$emit('input', this.valor)
       }
   },
   watch: {
         value: {
           immediate: true,
           handler (val) {
            if (typeof val === 'string') {
                if (val.toLowerCase() === 'true') {
                    this.valor = true
                } else {
                    this.valor = false
                }
            } else {
                this.valor = val
            }
           }
         },
         state: {
           immediate: true,
           handler (val) {
             this.stateOrigin = val
           }
         },
         feedback: {
             immediate: true,
             handler (val) {
                this.feedbackOrigin = val
           }
         }
    }
}
</script>
