function getNotifications (filter) {
        return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/notificacao/getNotifications`, filter)
                    .then(resp => {
                        if (resp.data.success) {
                            return Promise.resolve(resp.data)
                        } else {
                            return Promise.reject(resp.data)
                        }
                    })
    }

function getLatestNotifications () {
    return window.Vue.$httpNoInterceptor.get(`${process.env.VUE_APP_API_SERVER}/notificacao/getLatestNotifications`)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })
}

function setNotificationRead (ids) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/notificacao/markAsRead`, ids)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })
}

function setNotificationUnread (ids) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/notificacao/markAsUnread`, ids)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })
}

function getMenuPerfil () {
    return window.Vue.$httpNoInterceptor.get(`${process.env.VUE_APP_API_SERVER_V3}/menusperfil`)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })          
}

function getMenu () {
    return window.Vue.$httpNoInterceptor.get(`${process.env.VUE_APP_API_SERVER_V3}/menus`)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })
}

function getRestricaoHorarioAtiva () {
    return window.Vue.$httpNoInterceptor.get(`${process.env.VUE_APP_API_SERVER_V3}/restricaoHorarioAtiva`)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })
}

export { 
    getNotifications,
    getLatestNotifications,
    setNotificationRead,
    setNotificationUnread,
    getMenuPerfil,
    getMenu,
    getRestricaoHorarioAtiva
}
