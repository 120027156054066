import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/minhaConta',
    component: Layout,
    children: [
        {
            name: 'minhaConta-conta',
            path: 'conta',
            component: () => import('@/components/minhaConta/conta'),
            meta: {
                auth: true
            }
        },
        {
            name: 'minhaConta-perfil',
            path: 'perfil',
            component: () => import('@/components/minhaConta/perfil'),
            meta: {
                auth: true
            }
        },
        {
            name: 'minhaConta-badges',
            path: 'badges',
            component: () => import('@/components/minhaConta/badges'),
            meta: {
                auth: true
            }
        },
        {
            name: 'minhaConta-historicoConsumo',
            path: 'HistoricoConsumo',
            component: () => import('@/components/minhaConta/historicoConsumo'),
            meta: {
                auth: true
            }
        }
    ]
}]
