var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        scrollable: "",
        id: "ModalContratarMenu",
        size: "lg",
        "ok-only": ""
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "default" }, slot: "default" },
        [
          _c(
            "perfect-scrollbar",
            {
              attrs: {
                options: { suppressScrollX: true, wheelPropagation: true },
                id: "scrollContentBody"
              }
            },
            [
              _c("div", { staticClass: "d-block text-center" }, [
                _c("div", { staticClass: "mb-5" }, [
                  _c("p", { staticClass: "h3" }, [
                    _c("strong", { staticClass: "text-modal" }, [
                      _vm._v(
                        "Contrate o HFy de Saúde - Segfy e acesse esta funcionalidade"
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    alt: "Funcionalidade Indisponível",
                    src: _vm.baseUrl + "img/modalContratar/icone_unlock.png"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "h5 mt-4 ml-4 mr-4" }, [
                  _c("p", [
                    _c("strong", { staticClass: "text-modal" }, [
                      _vm._v(
                        "\n                              A Segfy trouxe mais uma novidade para você! Estamos fazendo uma parceria e agora, é possível cotar saúde direto na nossa plataforma!\n                          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", { staticClass: "text-modal" }, [
                      _vm._v(
                        "\n                              Entre em contato com nosso time de suporte pelo chat ou clique "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.segfy.com/falar-com-a-segfy/",
                            target: "_blank"
                          }
                        },
                        [_vm._v("aqui")]
                      ),
                      _vm._v(
                        " para pedir o seu acesso.\n                          "
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }