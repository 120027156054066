var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      ref: "dropdownUser",
      staticClass: "dropdown-user mr-2",
      attrs: { right: "", "no-caret": "" },
      on: { show: _vm.showMenu, hide: _vm.hideMenu }
    },
    [
      _c("template", { slot: "button-content" }, [
        _c(
          "div",
          {
            staticClass: "navbar-icon rounded-lg",
            class: _vm.menuAtivo ? "active" : ""
          },
          [_c("i", { staticClass: "navbar-user-icon lnr lnr-user p-2" })]
        )
      ]),
      _vm._v(" "),
      !_vm.isInTour
        ? _c("div", [
            _vm.isSegfyer
              ? _c("div", { staticClass: "custom-list-item d-flex p-4" }, [
                  _c("i", {
                    staticClass:
                      "custom-list-item-icon mr-3 my-auto lnr lnr-rocket"
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-list-item-content" }, [
                    _c(
                      "h5",
                      { staticClass: "font-weight-bold py-0 text-body" },
                      [_vm._v(_vm._s(_vm.nomeSegfyer))]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted mb-0 mt-1" }, [
                      _vm._v(_vm._s(_vm.emailSegfyer))
                    ])
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr", { staticClass: "my-0 mx-2" }),
      _vm._v(" "),
      !_vm.isInTour
        ? _c("div", [
            _c("div", { staticClass: "custom-list-item d-flex p-4" }, [
              _c("i", {
                staticClass: "custom-list-item-icon mr-3 my-auto lnr lnr-user"
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-list-item-content" },
                [
                  _c("h5", { staticClass: "font-weight-bold py-0 text-body" }, [
                    _vm._v(_vm._s(_vm.userName))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted mb-0 mt-1" }, [
                    _vm._v(_vm._s(_vm.userEmail))
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "mt-2",
                      attrs: { to: this.setURL("/minhaConta/Perfil") },
                      nativeOn: {
                        click: function($event) {
                          _vm.dropdownClick()
                        }
                      }
                    },
                    [_vm._v("Perfil")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr", { staticClass: "my-0 mx-2" }),
      _vm._v(" "),
      _c(
        "div",
        [
          !_vm.isInTour
            ? _c(
                "div",
                _vm._l(_vm.menuItems, function(elem) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: elem.title,
                      staticClass: "custom-list-item d-flex",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.dropdownClick(elem.link)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "custom-list-item-icon mr-3 my-auto",
                        class: elem.icon
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "custom-list-item-content" }, [
                        _c("h5", [_vm._v(_vm._s(elem.title))])
                      ])
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-list-item d-flex",
              on: {
                click: function($event) {
                  _vm.logoff()
                }
              }
            },
            [
              _c("i", {
                staticClass:
                  "custom-list-item-icon mr-3 my-auto ion ion-ios-log-out"
              }),
              _vm._v(" "),
              _c("div", { staticClass: "custom-list-item-content" }, [
                _c("h5", [_vm._v("Sair")])
              ])
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }