import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '../globals'

import homeRoutes from './home'
import authRoutes from './auth'
import basicRoutes from './basic'
import multicalculoRoutes from './multicalculo'
import seguradosRoutes from './segurados'
import onboardingRoutes from './onboarding'
import centralVendasRoutes from './centralVendas'
import sinistrosRoutes from './sinistros'
import financeiroRoutes from './financeiro'
import relatoriosRoutes from './relatorios'
import suporteRoutes from './suporte'
import configuracaoRoutes from './configuracao'
import minhaContaRoutes from './minhaConta'
import notificacaoesRoutes from './notificacao'
import buscasRoutes from './buscas'
import tarefasRoutes from './tarefas'
import marketingRoutes from './marketing'
import chatRoutes from './chat'
import assinatura from './assinatura'
import eventBus from '../eventBus/eventBus'
import descadastroEmailRoutes from './cancelamentoEmail'
import tabelaAutomatizacaoRoutes from './tabelaAutomatizacao'
import politicaRoutes from './politica'
import termoRoutes from './termos'
import permissoesSeguradosRoutes from './permissoesSegurados'
import telegramBotRoutes from './telegramBot'
import atualicacaoRoutes from './atualizacao'

import driver from '../driver'
import filtros from './filtros'
import moment from 'moment'
import { isFirefox } from '../extensions/utils'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
    { 
        path: '', 
        redirect: '/home' 
    },
    {
        path: '/teste',
        component: () => import('@/components/basic/teste')
    },
    { 
        path: '*', 
        component: () => import('@/components/basic/404')
    },
    { 
        path: '/400', 
        component: () => import('@/components/basic/400')
    },
    { 
        path: '/confirmacaoEmail', 
        component: () => import('@/components/confirmacaoEmail/confirmacaoEmail')
    }
].concat(basicRoutes)
 .concat(authRoutes)
 .concat(homeRoutes)
 .concat(multicalculoRoutes)
 .concat(seguradosRoutes)
 .concat(onboardingRoutes)
 .concat(centralVendasRoutes)
 .concat(sinistrosRoutes)
 .concat(financeiroRoutes)
 .concat(relatoriosRoutes)
 .concat(suporteRoutes)
 .concat(configuracaoRoutes)
 .concat(minhaContaRoutes)
 .concat(notificacaoesRoutes)
 .concat(buscasRoutes)
 .concat(tarefasRoutes)
 .concat(marketingRoutes)
 .concat(chatRoutes)
 .concat(assinatura)
 .concat(descadastroEmailRoutes)
 .concat(filtros) 
 .concat(tabelaAutomatizacaoRoutes)
 .concat(politicaRoutes)
 .concat(permissoesSeguradosRoutes)
 .concat(telegramBotRoutes)
 .concat(termoRoutes)
 .concat(atualicacaoRoutes)
 
const router = new Router({
    base: '/',
    mode: 'history',
    routes: ROUTES
})

router.afterEach((to, from, next) => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
    document.body.classList.add('app-loading')

    driver.driver.reset()

    if (globals().isMobile) {
        eventBus.$emit('resetInput')
    }
    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10)

    // Vamos checar se estamos direcionando o usuário
    // corretamente, baseado em seu status de autenticação
    checkAuth(to, from, next)
})

function checkAuth (to, from, next) {
    let state = Vue.$store.state

    if (to.path === '/multicalculo/orcamento-redirect') {
        next({ path: setURL('/multicalculo/hfy-auto') })

        if (isFirefox(navigator.userAgent)) {
            setTimeout(() => {
                console.log('redirect Firefox')
                window.location.reload()
            }, 3000)
          } else {
            // User is not using Firefox
            setTimeout(() => {
                console.log('redirect')
                window.location.reload()
            }, 1300)
          }
    }

    if (to.path.includes('/confirmacaoEmail')) {
        next({ component: () => import('@/components/confirmacaoEmail/confirmacaoEmail') })
    } else if (to.path !== '/assinatura/nova' && !to.path.includes('/logoff') && state.auth.isAuth && state.auth.dataTrialFim !== null && moment(state.auth.dataTrialFim) < moment()) {
        // se usuário em período TRIAL && Expirado
        next({ path: setURL('/assinatura/nova') })
    } else if (to.path.includes('/login') && state.auth.isAuth && !to.path.includes('/logoff')) {
        // Caso especial: pede a página de login, mas já está logado
        // É necessário checar com o server se está tudo bem mesmo
        // Caso sim, redirecionamos para a página inicial :)
        next({ path: '/home' })
    } else if (to.matched.some(m => m.meta.auth) && !state.auth.isAuth) {
        // Caso especial: pede página que necessita de autenticação,
        // mas não está logado. Redireciona para a página de login base
        next({ path: '/login?return_url=' + to.path })
    } else if (to.matched.some(m => m.meta.needsPermission) && !validatePermission(state, to.matched)) {
        // Se a página está marcada que precisa de permissão, verificar no login na store
        next({ path: setURL('/unauthorized') })
    } else if (to.query.redirect === undefined) {
        if (from.query.return_url !== undefined && state.auth.isAuth) {
            next({ path: from.query.return_url + '?redirect=true' })
        }
        if (Vue.$store.state.auth.isAuth) {
            // TODO: SignalR
            // connection.connection.start()
        }
    }
}

function validatePermission (state, matched) {
    let autorizado = true
    matched.filter(a => a.meta.needsPermission).forEach(function (item) {
        let prop = item.meta.needsPermission // qual a permissão que vamos procurar no auth store
        let permissao = state.auth[prop]
        if (item.meta.inversePermission === true) { // casos onde a propriedade está invertida, ie.: bloquearOrcamentoAuto
            permissao = !permissao
        }

        if (permissao === false) { // para qualquer rota sem permissão, já não deve ser autorizado
            autorizado = false
        }            
    })

    return autorizado
}

function setURL (link) {
    return link + '#navr=' + uid().split('-')[0]
}

function uid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0
        let v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export default router
