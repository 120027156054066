<template>
  <div class="d-flex flex-column" style="position: relative;">
    <div v-if="fadeMenu" class="backgroundFade"></div>
    <div class="sticky-navigation">
      <div v-if="this.$store.state.auth.temParcelaPendente===true" class="trial-bar inadimplente-bar">
        O seu último pagamento ainda
        <strong>não foi processado</strong>, verifique em
        <a
          href="javascript:void(0)"
          @click.prevent="onAssinarClick('/minhaConta/conta')"
        >minha conta</a> o histórico de pagamentos.
      </div>
      <nav
        id="step1-navbar"
        v-if="isTrial !== null"
        class="navbar navbar-light row navborder-bottom"
      >
        <div class="col-md">
          <span class="col-md-12 navbar-brand trial-title">Bem vindo ao Segfy!</span>
          <div class="w-100"></div>
          <span class="col-md-12 navbar-text trial-subtitle">Complete a configuração do sistema e comece a vender mais!</span>
        </div>
        <div class="col-md col-12 trial-steps ml-md-2 pl-md-3 pt-md-1">
          <div class="row">
            <div class="ml-3 trial-step">
              <img
                src="/img/stepIcons/step1.svg"
                v-if="!loginConfigurado"
                alt="Login nas seguradoras"
              />
              <i v-if="loginConfigurado" class="fa fa-check-circle" style="color:'#D25032'"></i>
              <span class="text-step">Login nas seguradoras</span>
            </div>
            <div class="ml-3 trial-step">
              <img src="/img/stepIcons/step2.svg" alt="Importar documentos" />
              <span class="text-step">Importar documentos</span>
            </div>
            <div class="ml-3 trial-step">
              <img src="/img/stepIcons/step3.svg" alt="HFy" />
              <span class="text-step">HFy</span>
            </div>
          </div>
        </div>
        <div class="col-md col-12 text-right">
          <div class="text-time-trial text-right">{{isTrial}}</div>
          <div class="w-100"></div>
          <span class="navbar-text trial-subtitle">
            <a
              href="javascript:void(0)"
              class="link-assinar"
              @click.prevent="onAssinarClick('/assinatura/nova')"
            >
              Assine já o sistema!
            </a>
          </span>

        </div>
      </nav>
      <nav
        id="navbarMenuTopoLayout"
        :class="getLayoutNavbarBg()"
        style="width: 100%;"
        :style="[isSegfyer === true ? {'background-color': '#FF5028'} : {}]"
        class="d-inline-flex justify-content-between flex-nowrap navbar-expand shadow-sm"
      >
        <!-- TOGGLE -->
        <b-navbar-nav class="layout-sidenav-toggle align-items-center d-inline-clock d-xl-none">
          <a
            class="mx-4 nav-item nav-link transition nav-toggle-menu"
            href="javascript:void(0)"
            @click="toggleSidenav"
          >
            <i class="fas fa-bars" />
          </a>
        </b-navbar-nav>

            <router-link :to="this.setURL('/home')" class="navbar-brand app-brand router-link-active py-0 justify-content-center" target="_self">
                <span class="app-brand-logo">
                    <img src='/img/logos/logo-segfy2.png' class="m-2"/>
                </span>
            </router-link>

        <!-- ITEMS DO MENU -->
        <b-navbar-nav
          class="d-inline-flex flex-row align-items-center justify-content-between flex-nowrap w-100"
          :class="{'navbar-trial': isTrial}"
        >
         <span style="color:#bcbcbc; font-size: 11px;" :title="this.$store.state.auth.nomeCorretora" > 
            <strong>
              {{

                  (trimmedName => {
                     return trimmedName.length > 20 ? trimmedName.substring(0, 20) + "..." : trimmedName;
                    })(this.$store.state.auth.nomeCorretora.trim())
                
              }}
            </strong> 
          </span>
          <search-bar />

          <!-- <webinars />-->

          <notify />

          <apps-menu />
          <user-options />
        </b-navbar-nav>
      </nav>
      <ModalBemVindo :showModalBemVindo="showModalBemVindo"></ModalBemVindo>
      <ModalVideo :showModalVideo="showModalVideo"></ModalVideo>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../../style/common.scss";

.navbar-nav {
  height: 56px;
}
.navborder-bottom {
  border-bottom: 2px solid #cccccc;
  border-radius: 0px 0px 8px 8px;
}
.layout-sidenav-toggle .nav-link.nav-toggle-menu {
  font-size: 28px;
  line-height: 56px;
  padding: 0px;
  color: $Grey_7;
  text-align: center;
  cursor: pointer;

  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.layout-sidenav-toggle .nav-link.nav-toggle-menu:hover {
  color: $Grey_7;
}

    .nav-toggle-menu.active{
        color:$Grey_7;
        // -webkit-transform: rotate(360deg);
        // -moz-transform: rotate(360deg);
        // -o-transform: rotate(360deg);
        // transform: rotate(360deg);
    }

    .navbar-brand{
        width: 14rem;
        padding:0px;
    }

    .navbar-brand .app-brand-logo>img{
        height: 35px;
        margin-bottom: 6px;
        margin-left: 0px;
    }
    .dropdown-toggle::after{
        display:none
    }

    @media (max-width: 768px) {
        .navbar-brand{
            width: auto;
        }
        .navbar-brand .app-brand-logo>img{
            height: 44px;
        }
        .mobile-user-options {
            display: block !important;
        }

        .mobile-notifications {
            display: block !important;
            background-color: white;
            position: absolute;
            height: calc(100% - 59.73px);
            top: 59.73px;
            width: 100%;
        }
    }

    .trial-bar{
        background:$segfy_1;
        color:white;
        text-align: center;

        a {
            color:white;
            text-decoration: underline;
        }
    }
    .inadimplente-bar{
        background:$warning;
        color:$dark;
        text-align: center;

  a {
    color: $dark;
    text-decoration: underline;
  }
}
@media (max-width: 768px) {
  .trial-bar {
    height: 30px;
    line-height: 14px;
  }
  .trial-bar > a {
    display: block;
  }
}

.trial-title {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 21px;
  color: #d25032 !important;
}

.trial-subtitle {
  font-weight: normal !important;
  font-size: 12px !important;
  color: #666666 !important;
  margin: 0;
  padding: 0;
}
.text-time-trial {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #666666 !important;
}
.text-step {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #666666 !important;
}
.link-assinar {
  font-weight: normal !important;
  font-size: 12px !important;
  color: #d25032 !important;
}
.reset-tour{
  pointer-events: all !important;
  z-index: 100100 !important;
  border: none !important;
  padding: 10px 10px !important;
  font: 12px/normal sans-serif !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  text-align: center !important;
  text-shadow: none !important;
  color: #FFFFFF !important;
  background: #D25032 !important;
}
.reset-tour:hover{
  text-decoration: underline;
}
</style>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import SearchBar from './searchBar'
import Notifications from './notifications'
import NofityWrapper from '../../../components/globalNotification/notification'
import Webinars from './webinars'
import UserOptions from './userOptions'
import AppsMenu from './appsMenu'
import ModalBemVindo from '../../../components/common/modalBemVindo'
import ModalVideo from '../../../components/common/modalVideo'
export default {
  name: 'app-layout-navbar',
  computed: {
    isTrial () {
      if (
        this.$store.state.auth.dataTrialInicio &&
        this.$store.state.auth.dataTrialFim
      ) {
        let inicio = moment(this.$store.state.auth.dataTrialInicio)
        let expiracao = moment(this.$store.state.auth.dataTrialFim)

        let quantidadeDias = expiracao.diff(inicio, 'days')

        let hoje = moment()
        let dias = quantidadeDias - hoje.diff(inicio, 'days')
        if (this.$store.state.auth.primeiroAcesso && this.step === 0) {
          window.Vue.onboarding.driver.nextStep()
        }
        if (dias === 2) {
          return `Amanhã é o último dia de avaliação.`
        } else if (dias > 2) {
          return `Você tem ${dias} dias de teste grátis.`
        } else if (dias >= 0 && expiracao > hoje) {
          return `Hoje é o último dia de avaliação.`
        } else if (dias <= 0 && expiracao < hoje) {
          return `Seu período de avaliação terminou no dia ${moment(
            expiracao
          ).format('DD/MM/YYYY [as] HH:mm')}.`
        }
      }
      return null
    },
    ...mapState('newOnboarding', ['step', 'loginConfigurado', 'fadeMenu'])
  },
  watch: {
    step: function (val) {
      if (val === 1) {
        this.showModalBemVindo = false
        this.showModalVideo = false
      setTimeout(() => {
        let element = document.getElementById('menu-home')
        element.click()
          window.Vue.onboarding.driver.reset()
          this.showModalBemVindo = true
          window.Vue.onboarding.driver.setStep(1)
        }, 500)
      } else if (val === 2) {
         this.showModalBemVindo = false
         this.showModalVideo = true
         window.Vue.onboarding.driver.setStep(2)
       }
    }
  },
  components: {
    'search-bar': SearchBar,
    notifications: Notifications,
    notify: NofityWrapper,
    'user-options': UserOptions,
    'apps-menu': AppsMenu,
    webinars: Webinars,
    ModalBemVindo: ModalBemVindo,
    ModalVideo: ModalVideo
  },
  data: function () {
    return {
      showModalBemVindo: false,
      showModalVideo: false
    }
  },

  props: {
    sidenavToggle: { type: Boolean, default: true }
  },
  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

        getLayoutNavbarBg () {
            if (this.$store.state.auth.segfyer) return this.layoutNavbarSegfyerBg
            return this.layoutNavbarBg
        },

    onAssinarClick (link) {
      if (link.indexOf('http') >= 0) {
        let win = window.open(link, '_blank')
        win.focus()
      } else {
        this.$router.push(this.setURL(link))
      }
    },
    ...mapActions({
      toggleSideMenu: 'settings/toggleSideMenu'
    })
  }
}
</script>
