<template>
    <b-modal scrollable id="ModalContratarMenu" size="lg" ok-only >
        <div slot="default">
            <perfect-scrollbar :options="{ suppressScrollX: true, wheelPropagation: true }" id="scrollContentBody">
                <div class="d-block text-center">
                    <div class = "mb-5" >
                        <p class="h3"><strong class="text-modal">Contrate o HFy de Saúde - Segfy e acesse esta funcionalidade</strong></p>
                    </div>
                    <img alt="Funcionalidade Indisponível" :src="`${baseUrl}img/modalContratar/icone_unlock.png`" />
                    <div class="h5 mt-4 ml-4 mr-4">
                        <p>
                            <strong class="text-modal">
                                A Segfy trouxe mais uma novidade para você! Estamos fazendo uma parceria e agora, é possível cotar saúde direto na nossa plataforma!
                            </strong>
                        </p>
                        <p>
                            <strong class="text-modal">
                                Entre em contato com nosso time de suporte pelo chat ou clique <a href="https://www.segfy.com/falar-com-a-segfy/" target="_blank">aqui</a> para pedir o seu acesso.
                            </strong>
                        </p>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
  </b-modal>
</template>
<script>
import PerfectScrollbar from '@/vendor/libs/perfect-scrollbar/PerfectScrollbar'
export default {
    name: 'ModalContratarMenu',
    data () {
        return {
            title: '',
            description: '',
            link: 'https://conteudo.segfy.com/multicalculo-saude',
            message: ''
        }
    },
    components: {
        PerfectScrollbar
    },
    methods: {
        openModal (notificacao) {
            Object.assign(this, notificacao)
            this.$bvModal.show('ModalContratarMenu')
        },
        closeModal (close) {
            this.$bvModal.hide('ModalContratarMenu')
        },
        gotoLink () {
            var win = window.open(this.link, '_blank')
            win.focus()
        }

    }
}
</script>
<style lang="scss">
 @import "../../vendor/styles/_appwork/_include";
    @import "../../vendor/styles/_appwork/_variables";
 .modal-header {
    border-bottom: none;
 }
 .modal-footer {
    border-top: none;
 }
 .close {
    font-size: 40px;
    top: 40% !important;
}
.text-modal{
    color: $dark;
}
.btnContratar-modal {
    background: #D25032;
    color: #fff;
}
.btnContratar-modal:hover {
background: #D25032;
color: #fff;
box-shadow: 0px 2px 3px #808080;
}
</style>
