import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/tarefas',
    component: Layout,
    children: [
        {
            name: 'lista-tarefas',
            path: '',
            component: () => import('@/components/tarefas/listaTarefas'),
            meta: {
                auth: true
            }
        }
    ]
}]
