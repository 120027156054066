<template>
   <BlockUI :message="message" :html="html" v-if="loadingOrigin" /> 
</template>
<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>
<style>
    .loading-container .loading-backdrop {
        position: absolute !important;
        background: #fff !important;
        opacity: .7 !important;
    }
    .loading-container .loading {
        background: none !important;
        box-shadow: none !important;
        position: absolute !important;
    }
</style>
<script>
export default {
   name: 'block-ui',
   props: ['loading', 'message'],
   data () {
       return {
            timeOut: null,
            loadingOrigin: this.loading,
            html: `
<div class="sk-folding-cube sk-primary"><div class="sk-cube1 sk-cube"></div><div class="sk-cube2 sk-cube"></div><div class="sk-cube4 sk-cube"></div><div class="sk-cube3 sk-cube"></div></div>`
       }
   },
 watch: {
         loading: {
           immediate: true,
           handler (val) {
             if (val === true) {
               let vm = this
              this.timeOut = setTimeout(function () {
                vm.loadingOrigin = true
              }, 1000)
            } else {
              clearTimeout(this.timeOut)
              this.loadingOrigin = false
            }
           }
         }
    }
}
</script>
