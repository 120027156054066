var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-wrapper layout-1" },
    [
      _c(
        "div",
        { staticClass: "layout-inner" },
        [
          _c("app-layout-navbar"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "layout-container" },
            [
              _c("app-layout-sidenav"),
              _vm._v(" "),
              _c("div", { staticClass: "layout-content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "router-transitions container-fluid d-flex align-items-stretch flex-grow-1 p-0"
                  },
                  [_c("router-view", { key: _vm.$route.fullPath })],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "layout-overlay",
        on: { click: _vm.closeSidenav }
      }),
      _vm._v(" "),
      _c("ModalPlugin", { ref: "modalPlugin" }),
      _vm._v(" "),
      _c("ModalNovidades", {
        ref: "modalNovidades",
        attrs: { mostrarSlide: _vm.mostrarSlide }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }