<template>
    <b-nav-item-dropdown 
        right
        no-caret 
        ref="dropdown-webinars"
        class="dropdown-webinars mr-1"
        @show="showMenu"
        @hide="hideMenu"
        v-if="isXS === false && isSM === false">

        <template slot="button-content">
            <div class="navbar-icon" :class="(menuAtivo ? 'active' : '')">
                <label v-if="live === true" class="text-webinar-live">LIVE</label>
                <i class="fas fa-tv p-2"></i>
            </div>
        </template>

        <div class="webinars-list">
            <!-- Lista de novas notificações -->
            <b-list-group flush>
                    <b-dropdown-item v-if="webinar !== null" @click.stop.prevent="goToWebinar(webinar)" class="custom-list-item d-flex mx-2" >
                        <div class="custom-list-item-content w-100 d-block">
                            <h6 v-if="live === true" class="text-body text-bold mb-1">Webinar:</h6>
                            <h6 v-if="live === false" class="text-body text-bold mb-1">Próximo Webinar:</h6>
                            <small class="text-light text-nowrap">{{timeToWebinar}}</small>
                            <p>{{webinar.titulo}}</p>
                        </div>
                    </b-dropdown-item>
                <b-list-group-item class="p-0">
                    <a href="javascript:void(0)" @click="showAllWebinars()" class="d-block bg-white text-center small p-3">Exibir todas as webinars</a>
                </b-list-group-item >
            </b-list-group>
            
        </div>

    </b-nav-item-dropdown>
</template>

<style lang="scss">
    @import "../../../style/common.scss";

    .text-webinar-live {
        margin: 0;
        position: absolute;
        font-size: 10px;
        left: 5px;
        margin-top: -9px;
        padding: 0 10px;
        color: #fff;
        background-color: $segfy_1;
    }

    .navbar-icon > i {
        color: $Grey_7;
    }
    
    .dropdown-webinars {
        a{
            display: flex;
            align-items: center;
        }
        > a > span{
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 20px;
            margin: 0px;
            padding: 1px 2px 0px 2px;
        }
        .badge {
            background-color:$segfy_1;
        }
        .webinars-list{
            background-color:#fff;
        }
        .dropdown-menu{
            padding: 0px;
            border: 0px;
            border-radius: 0.25rem;
            overflow:hidden;
            background-color: transparent;
            width: 310px;
        }

        .webinar-title{
            background-color:#fff;
            text-align: center;
            padding: 1rem;
            font-weight: 300;
        }
    }

    @media (max-width: 768px) {
    .dropdown-webinars .dropdown-menu {
        position: fixed !important;
        top: 56px !important;
        left: 0px !important;
        right: 0px !important;
        width:100%;
    }
    }

    .webinars-list {
        background: #fff;

        li.custom-list-item {
            border-bottom: 1px solid $Grey_3;
            padding: 0.5rem 0 !important;
        }
        li.custom-list-item:last-of-type{
            border-bottom: none;
        }
        li > a.dropdown-item{
            border-radius: 0.25rem !important;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            
            &:not(.disabled).active, 
            &:not(.disabled):active {
                color: $Grey_8;
                background-color: rgba(24, 28, 33, 0.03);
            }
        }
        
        li > a.dropdown-item:hover a.mark-as-read{
            opacity: 1;
            cursor: pointer;
        }
        a.mark-as-read{
            opacity: 0;
            -webkit-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            -o-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }
        a.mark-as-read:hover{
            color:$LightGreen_2 !important;
        }
    }

    .dropdown-menu-right {
        right: 0px;
        left: auto;
    }

    .bell{
        font-size:24px;
        color: $Grey_7;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        // &:hover,
        // &:active,
        // &.active{
        //     color:#fff;
        // }
    }

    @media (max-width: 768px) {
        .dropdown-webinars .dropdown-toggle::after{
            display: none;
        }
    }
    
</style>

<script>
import moment from 'moment'
import { getNextWebinar } from '../../../components/suporte/methods.js'

export default {
    name: 'webinars',

    data: () => ({
            menuAtivo: false,
            webinar: null,
            live: false
    }),
    computed: {
        timeToWebinar () {
            return this.getwebinarTime(this.webinar.data)
        }
    },
    methods: {
        showAllWebinars: function () {
            this.$router.push(this.setURL('/suporte/webinar'))
            this.$refs['dropdown-webinars'].$el.children[0].click()
        },

        showMenu: function () {
            this.menuAtivo = true
        },

        hideMenu: function () {
            this.menuAtivo = false
        },

        getwebinarTime: function (value) {
            if (value === undefined) return 0
            return moment(value).fromNow()
        },

        requestwebinars: function () {
            getNextWebinar()
                .then(resp => {
                   this.webinar = resp.data
                   this.live = resp.data.live
                })
        },
        goToWebinar (webinar) {
            var win = window.open(webinar.link, '_blank')
            win.focus()
        }
    },

    created: function () {
        this.requestwebinars()
        let vm = this
        window.Vue.hub.connectionFaye.sub('webinar', function (live) {
            vm.live = live 
        }, false, true)
    },

    beforeDestroy: function () {
        clearInterval(this.ticker)
    }
    
}

</script>
