// REQUEST: https://segfy.com/api/login (POST)
// params
//
// RESPONSE:
// sucess: Boolean
// error
//    user: Boolean [true se erro no user]
//    password: Boolean [true se erro no password]
function normalLogin (login) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER_V3}/login`, login)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function assinaturaLogin (login) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/loginAssinatura`, login)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function trialLogin (login) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/loginTrial`, login)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function segfyerLogin (login) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/segfyer`, login)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function unblockUser (token) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER_V3}/unblock`, token)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function getInfoFromEmail (email) {
    return window.Vue.$http.get(`${process.env.VUE_APP_API_SERVER}/getinfo/${email}`)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function impersonateLogin (userEmail, userPassword, impersonateEmail) {
    return window.Vue.$http.get('https://villa.segfy.com/api/login', {
            params: {
                userEmail: userEmail,
                userPassword: userPassword,
                impersonateEmail: impersonateEmail
            }
        })
        .then(resp => {
            return resp.data.success ? Promise.resolve(resp.data) : Promise.reject(resp.data)
        })
}

function sendNewPassword (userEmail) {
    let email = { email: userEmail }
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/forgotpassword`, email)
    .then(resp => {
        if (resp.data.success) {
            return Promise.resolve(resp.data)
        } else {
            return Promise.reject(resp.data)
        }
    })
}

function changePassword (data) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/changeexpiredpassword`, data)
    .then(resp => {
        if (resp.data.success) {
            return Promise.resolve(resp.data)
        } else {
            return Promise.reject(resp.data)
        }
    })
}

// --------
// MIGRAÇÃO
// --------
function migrateUser (migrate) {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER}/migrateuser`, migrate)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

function logoffUser () {
    return window.Vue.$http.post(`${process.env.VUE_APP_API_SERVER_V3}/logoff`)
        .then(resp => {
            if (resp.data.success) {
                return Promise.resolve(resp.data)
            } else {
                return Promise.reject(resp.data)
            }
        })
}

export { 
    normalLogin,
    getInfoFromEmail, 
    impersonateLogin, 
    sendNewPassword, 
    changePassword, 
    migrateUser,
    unblockUser,
    segfyerLogin,
    trialLogin,
    logoffUser,
    assinaturaLogin
}
