function getTarefas () {
    return window.Vue.$httpCookiesOnly.get(`${process.env.VUE_APP_BUDGET_SERVER}/api/Task/Count`)
                .then(resp => {
                    if (resp.data.success) {
                        return Promise.resolve(resp.data)
                    } else {
                        return Promise.reject(resp.data)
                    }
                })
            }

export { 
    getTarefas
}
