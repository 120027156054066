import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/configuracao',
    component: Layout,
    children: [
        {
            name: 'configuracao-paginaPublica',
            path: 'paginaPublica',
            component: () => import('@/components/configuracao/paginaPublica'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-corretoras',
            path: 'corretoras',
            component: () => import('@/components/configuracao/corretoras'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-ramos',
            path: 'ramos',
            component: () => import('@/components/configuracao/ramos'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-seguradoras',
            path: 'seguradoras',
            component: () => import('@/components/configuracao/seguradoras'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-produtores',
            path: 'produtores',
            component: () => import('@/components/configuracao/produtores'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-usuarios',
            path: 'usuarios',
            component: () => import('@/components/configuracao/usuarios'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-usuarios-usuario',
            path: 'usuarios/usuario',
            component: () => import('@/components/configuracao/usuarios'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-usuarios-novo',
            path: 'usuarios/novo',
            component: () => import('@/components/configuracao/usuarios-novo'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-parametros',
            path: 'parametros',
            component: () => import('@/components/configuracao/parametros'),
            meta: {
                auth: true
            }
        },
        {
            name: 'configuracao-loginsSeguradoras',
            path: 'SeguradorasLogin',
            component: () => import('@/components/configuracao/seguradorasLogin'),
            meta: {
                auth: true
            }
        }
    ]
}]
