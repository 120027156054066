<template>
  <b-modal id="modalbv" hide-header-close hide-header hide-footer no-close-on-backdrop no-close-on-esc>
    <div class="container-flex">
      <div class="row justify-content-center">
        <div class="col-12">
          <p class="text-title text-center">Bem vindo ao Segfy!</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <img src="/img/modalTourGuide/bemvindo.svg" alt="Bem vindo" />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 pt-3 pb-3">
          <span
            class="d-flex text-footer justify-content-center"
          >O software mais poderoso para corretoras de seguros.</span>
          <span
            class="d-flex text-footer justify-content-center"
          >Intuitivo, inovador e eficiente na gestão com controles precisos.</span>
        </div>
      </div>
      <div class="row justify-content-center mt-3 mb-3">
        <button class="btn align-self-center buttonProsseguir" @click.stop="nextStep()">Prosseguir</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'modalBemVindo',
  props: ['showModalBemVindo'],
  watch: {
    showModalBemVindo: function (val) {
      if (this.showModalBemVindo) this.$bvModal.show('modalbv')
      else this.$bvModal.hide('modalbv')
    }
  },
  methods: {
    nextStep () {
      this.$bvModal.hide('modalbv')
      window.Vue.onboarding.driver.nextStep()
    }
  }
}
</script>
<style>
.buttonProsseguir {
  padding: 7px 15%;
  background: #d25032;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.text-title {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #666666;
}
.text-footer {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #666666;
}
</style>
