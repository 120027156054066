import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/LGPD/permissoesSegurados',
    component: Layout,
    children: [
        {
            path: '',
            component: () => import('@/components/LGPD/permissoesSegurados'),
            meta: {
                auth: true
            } 
        }
    ]
}]
