<template>
    <b-nav-item-dropdown 
        right
        no-caret 
        ref="dropdown-notifications"
        class="dropdown-notifications mr-1"
        @show="showMenu"
        @hide="hideMenu">

        <template slot="button-content">
            <div class="navbar-icon" :class="(menuAtivo ? 'active' : '')">
                    <i class="bell lnr lnr-alarm p-2" :class="(numberOfNewNotifications > 0 ? 'bell-ring' : '')"></i>
            </div>
            <span v-if="numberOfNewNotifications > 0" class="badge badge-primary badge-dot"></span>
            <!-- {{numberOfNewNotifications}} -->
        </template>

        <!-- Banner de novas notificações -->
        <div class="notification-title font-weight-normal" v-if="numberOfNewNotifications === 0">
            Não há novas notificações
        </div>
        <div class="notification-title" v-else-if="numberOfNewNotifications === 1">
            Há 1 nova notificação
        </div>
        <div class="notification-title" v-else>
            Há {{numberOfNewNotifications}} novas notificações
        </div>

        <div class="notifications-list">
            <!-- Lista de novas notificações -->
            
            <b-list-group flush>
                <perfect-scrollbar ref="scrollDiv" :options="{ wheelPropagation: true }" style="max-height: 350px">
                    <b-dropdown-item @click.prevent="markAsRead($event, notification)" v-for='notification in notifications' :key='notification.id' class="custom-list-item d-flex mx-2" :class="{'opacity-50': notification.lido}" >
                        <i class="custom-list-item-icon mr-3 my-auto " :class="notification.tipo|icon" ></i>
                        <div class="custom-list-item-content w-100 d-block">
                            <h6 class="text-body">{{notification.titulo}}</h6>
                            <p>{{notification.descricao}}</p>
                            <div class="mt-2 d-flex justify-content-between">
                                <small class="text-light text-nowrap">{{getNotificationTime(notification.data)}}</small>
                                <a v-if="!notification.lido" href="javascript:void(0)" class="text-light text-nowrap mark-as-read" @click.prevent="markAsRead($event, notification, false)"><small>Marcar como lido <i class="fas fa-check ml-1"></i></small></a>
                            </div>
                        </div>
                    </b-dropdown-item>
                </perfect-scrollbar>

                <b-list-group-item class="p-0">
                    <a href="javascript:void(0)" @click="showAllNotifications()" class="d-block bg-white text-center small p-3">Exibir todas as notificações</a>
                </b-list-group-item >
            </b-list-group>
            
        </div>

    </b-nav-item-dropdown>
</template>

<style lang="scss">
    @import "../../../style/common.scss";
    
    .dropdown-notifications {
        a{
            display: flex;
            align-items: center;
        }
        > a > span{
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 20px;
            margin: 0px;
            padding: 1px 2px 0px 2px;
        }
        .badge {
            background-color:$segfy_1;
        }
        .notifications-list{
            background-color:#fff;
        }
        .dropdown-menu{
            padding: 0px;
            border: 0px;
            border-radius: 0.25rem;
            overflow:hidden;
            background-color: transparent;
            width: 310px;
        }

        .notification-title{
            background-color:#fff;
            text-align: center;
            padding: 1rem;
            font-weight: 300;
        }
    }

    @media (max-width: 768px) {
    .dropdown-notifications .dropdown-menu {
        position: fixed !important;
        top: 56px !important;
        left: 0px !important;
        right: 0px !important;
        width:100%;
    }
    }

    .notifications-list {
        background: #fff;

        li.custom-list-item {
            border-bottom: 1px solid $Grey_3;
            padding: 0.5rem 0 !important;
        }
        li.custom-list-item:last-of-type{
            border-bottom: none;
        }
        li > a.dropdown-item{
            border-radius: 0.25rem !important;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            
            &:not(.disabled).active, 
            &:not(.disabled):active {
                color: $Grey_8;
                background-color: rgba(24, 28, 33, 0.03);
            }
        }
        
        li > a.dropdown-item:hover a.mark-as-read{
            opacity: 1;
            cursor: pointer;
        }
        a.mark-as-read{
            opacity: 0;
            -webkit-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            -o-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }
        a.mark-as-read:hover{
            color:$LightGreen_2 !important;
        }
    }

    .dropdown-menu-right {
        right: 0px;
        left: auto;
    }

    .bell{
        font-size:24px;
        color: $Grey_7 !important;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        // &:hover,
        // &:active,
        // &.active{
        //     color:#fff;
        // }
    }

    .bell-ring{
        display:block;
        color: $segfy_1 !important;
        -webkit-animation: ring 4s .7s ease-in-out infinite;
        -webkit-transform-origin: 50% 4px;
        -moz-animation: ring 4s .7s ease-in-out infinite;
        -moz-transform-origin: 50% 4px;
        animation: ring 4s .7s ease-in-out infinite;
        transform-origin: 50% 4px;
    }
    @media (max-width: 768px) {
        .dropdown-notifications .dropdown-toggle::after{
            display: none;
        }
    }
    
    @-webkit-keyframes ring {
        0% { -webkit-transform: rotateZ(0); }
        1% { -webkit-transform: rotateZ(30deg); }
        3% { -webkit-transform: rotateZ(-28deg); }
        5% { -webkit-transform: rotateZ(34deg); }
        7% { -webkit-transform: rotateZ(-32deg); }
        9% { -webkit-transform: rotateZ(30deg); }
        11% { -webkit-transform: rotateZ(-28deg); }
        13% { -webkit-transform: rotateZ(26deg); }
        15% { -webkit-transform: rotateZ(-24deg); }
        17% { -webkit-transform: rotateZ(22deg); }
        19% { -webkit-transform: rotateZ(-20deg); }
        21% { -webkit-transform: rotateZ(18deg); }
        23% { -webkit-transform: rotateZ(-16deg); }
        25% { -webkit-transform: rotateZ(14deg); }
        27% { -webkit-transform: rotateZ(-12deg); }
        29% { -webkit-transform: rotateZ(10deg); }
        31% { -webkit-transform: rotateZ(-8deg); }
        33% { -webkit-transform: rotateZ(6deg); }
        35% { -webkit-transform: rotateZ(-4deg); }
        37% { -webkit-transform: rotateZ(2deg); }
        39% { -webkit-transform: rotateZ(-1deg); }
        41% { -webkit-transform: rotateZ(1deg); }

        43% { -webkit-transform: rotateZ(0); }
        100% { -webkit-transform: rotateZ(0); }
    }

    @-moz-keyframes ring {
        0% { -moz-transform: rotate(0); }
        1% { -moz-transform: rotate(30deg); }
        3% { -moz-transform: rotate(-28deg); }
        5% { -moz-transform: rotate(34deg); }
        7% { -moz-transform: rotate(-32deg); }
        9% { -moz-transform: rotate(30deg); }
        11% { -moz-transform: rotate(-28deg); }
        13% { -moz-transform: rotate(26deg); }
        15% { -moz-transform: rotate(-24deg); }
        17% { -moz-transform: rotate(22deg); }
        19% { -moz-transform: rotate(-20deg); }
        21% { -moz-transform: rotate(18deg); }
        23% { -moz-transform: rotate(-16deg); }
        25% { -moz-transform: rotate(14deg); }
        27% { -moz-transform: rotate(-12deg); }
        29% { -moz-transform: rotate(10deg); }
        31% { -moz-transform: rotate(-8deg); }
        33% { -moz-transform: rotate(6deg); }
        35% { -moz-transform: rotate(-4deg); }
        37% { -moz-transform: rotate(2deg); }
        39% { -moz-transform: rotate(-1deg); }
        41% { -moz-transform: rotate(1deg); }

        43% { -moz-transform: rotate(0); }
        100% { -moz-transform: rotate(0); }
    }

    @keyframes ring {
        0% { transform: rotate(0); }
        1% { transform: rotate(30deg); }
        3% { transform: rotate(-28deg); }
        5% { transform: rotate(34deg); }
        7% { transform: rotate(-32deg); }
        9% { transform: rotate(30deg); }
        11% { transform: rotate(-28deg); }
        13% { transform: rotate(26deg); }
        15% { transform: rotate(-24deg); }
        17% { transform: rotate(22deg); }
        19% { transform: rotate(-20deg); }
        21% { transform: rotate(18deg); }
        23% { transform: rotate(-16deg); }
        25% { transform: rotate(14deg); }
        27% { transform: rotate(-12deg); }
        29% { transform: rotate(10deg); }
        31% { transform: rotate(-8deg); }
        33% { transform: rotate(6deg); }
        35% { transform: rotate(-4deg); }
        37% { transform: rotate(2deg); }
        39% { transform: rotate(-1deg); }
        41% { transform: rotate(1deg); }

        43% { transform: rotate(0); }
        100% { transform: rotate(0); }
    }
</style>

<script>
import moment from 'moment'
import eventBus from '../../../eventBus/eventBus'
import PerfectScrollbar from '@/vendor/libs/perfect-scrollbar/PerfectScrollbar'
import { getLatestNotifications, setNotificationRead } from '../../../components/notificacao/methods.js'

export default {
    name: 'notifications',

    data: () => ({
            menuAtivo: false,
            notifications: [],
            numberOfNewNotifications: 0,
            ticker: null
    }),

    components: {
        PerfectScrollbar
    },

    filters: {
        icon: function (value) {
            switch (value) {
                case 1: return 'lnr lnr-screen' // 'fas fa-robot'
                case 2: return 'lnr lnr-user' // 'fas fa-user-tie' 
                case 3: return 'far fa-money-bill-alt' // 'fas fa-comment-dollar'
                case 4: return 'fas fa-info' // 'fas fa-life-ring'
                case 5: return 'lnr lnr-file-empty' // 'fas fa-file-download'
                default:
                    return 'fas fa-exclamation'
            }
        }
    },

    methods: {
        showAllNotifications: function () {
            this.$router.push(this.setURL('/notificacoes'))
            this.$refs['dropdown-notifications'].$el.children[0].click()
        },

        showMenu: function () {
            this.menuAtivo = true
        },

        hideMenu: function () {
            this.menuAtivo = false
        },

        getNotificationTime: function (value) {
            return moment(value).fromNow()
        },

        requestNotifications: function () {
            getLatestNotifications()
                .then(resp => {
                   this.notifications = resp.data.notificacoes
                    this.numberOfNewNotifications = resp.data.total
                })
        },

        markAsRead: function (e, notification, redirect = true) {
            e.stopPropagation()
            notification.lido = true
            this.numberOfNewNotifications--
            let itemId = []
            itemId.push(notification.id)

            setNotificationRead(itemId)
                .then(resp => {
                    if (!resp.success) {     
                        notification.lido = false
                        this.numberOfNewNotifications++
                        window.Vue.$noty.error(resp.message)
                    } else {
                        eventBus.$emit('notificacoesAlteradasBody')
                    }
                })

                if (redirect === false) return
                if (notification.link.indexOf('http') >= 0) {
                    let win = window.open(notification.link, '_blank')
                    win.focus()
                }
        }
    },

    created: function () {
        this.requestNotifications()
        let vm = this
        window.Vue.hub.connectionFaye.sub('notification', function (notificacao) {
            vm.numberOfNewNotifications++
            vm.notifications.unshift(notificacao)  
        }, true, true)

        // this.ticker = setInterval(() => {
        //     this.requestNotifications()
        // }, 1000 * parseInt(process.env.VUE_APP_NOTIFICATION_INTERVAL, 10))
        
        // TODO: SignalR
        // let vm = this
        // window.Vue.signalr.connection.addMethods('addNotification', function (item) {
        //     vm.numberOfNewNotifications++
        //     vm.notifications.unshift(item)
        // })
        eventBus.$on('notificacoesAlteradasLayout', (resp) => { 
            this.requestNotifications()
        })
    },

    beforeDestroy: function () {
        clearInterval(this.ticker)
    }
    
}

</script>
