var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column",
      staticStyle: { position: "relative" }
    },
    [
      _vm.fadeMenu ? _c("div", { staticClass: "backgroundFade" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sticky-navigation" },
        [
          this.$store.state.auth.temParcelaPendente === true
            ? _c("div", { staticClass: "trial-bar inadimplente-bar" }, [
                _vm._v("\n      O seu último pagamento ainda\n      "),
                _c("strong", [_vm._v("não foi processado")]),
                _vm._v(", verifique em\n      "),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.onAssinarClick("/minhaConta/conta")
                      }
                    }
                  },
                  [_vm._v("minha conta")]
                ),
                _vm._v(" o histórico de pagamentos.\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isTrial !== null
            ? _c(
                "nav",
                {
                  staticClass: "navbar navbar-light row navborder-bottom",
                  attrs: { id: "step1-navbar" }
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md col-12 trial-steps ml-md-2 pl-md-3 pt-md-1"
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "ml-3 trial-step" }, [
                          !_vm.loginConfigurado
                            ? _c("img", {
                                attrs: {
                                  src: "/img/stepIcons/step1.svg",
                                  alt: "Login nas seguradoras"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loginConfigurado
                            ? _c("i", {
                                staticClass: "fa fa-check-circle",
                                staticStyle: { color: "'#D25032'" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-step" }, [
                            _vm._v("Login nas seguradoras")
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _vm._m(2)
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md col-12 text-right" }, [
                    _c("div", { staticClass: "text-time-trial text-right" }, [
                      _vm._v(_vm._s(_vm.isTrial))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-100" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "navbar-text trial-subtitle" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link-assinar",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.onAssinarClick("/assinatura/nova")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            Assine já o sistema!\n          "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass:
                "d-inline-flex justify-content-between flex-nowrap navbar-expand shadow-sm",
              class: _vm.getLayoutNavbarBg(),
              staticStyle: { width: "100%" },
              style: [
                _vm.isSegfyer === true ? { "background-color": "#FF5028" } : {}
              ],
              attrs: { id: "navbarMenuTopoLayout" }
            },
            [
              _c(
                "b-navbar-nav",
                {
                  staticClass:
                    "layout-sidenav-toggle align-items-center d-inline-clock d-xl-none"
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "mx-4 nav-item nav-link transition nav-toggle-menu",
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.toggleSidenav }
                    },
                    [_c("i", { staticClass: "fas fa-bars" })]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "navbar-brand app-brand router-link-active py-0 justify-content-center",
                  attrs: { to: this.setURL("/home"), target: "_self" }
                },
                [
                  _c("span", { staticClass: "app-brand-logo" }, [
                    _c("img", {
                      staticClass: "m-2",
                      attrs: { src: "/img/logos/logo-segfy2.png" }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                {
                  staticClass:
                    "d-inline-flex flex-row align-items-center justify-content-between flex-nowrap w-100",
                  class: { "navbar-trial": _vm.isTrial }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#bcbcbc", "font-size": "11px" },
                      attrs: { title: this.$store.state.auth.nomeCorretora }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (function(trimmedName) {
                                return trimmedName.length > 20
                                  ? trimmedName.substring(0, 20) + "..."
                                  : trimmedName
                              })(this.$store.state.auth.nomeCorretora.trim())
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("search-bar"),
                  _vm._v(" "),
                  _c("notify"),
                  _vm._v(" "),
                  _c("apps-menu"),
                  _vm._v(" "),
                  _c("user-options")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("ModalBemVindo", {
            attrs: { showModalBemVindo: _vm.showModalBemVindo }
          }),
          _vm._v(" "),
          _c("ModalVideo", { attrs: { showModalVideo: _vm.showModalVideo } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md" }, [
      _c("span", { staticClass: "col-md-12 navbar-brand trial-title" }, [
        _vm._v("Bem vindo ao Segfy!")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100" }),
      _vm._v(" "),
      _c("span", { staticClass: "col-md-12 navbar-text trial-subtitle" }, [
        _vm._v("Complete a configuração do sistema e comece a vender mais!")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-3 trial-step" }, [
      _c("img", {
        attrs: { src: "/img/stepIcons/step2.svg", alt: "Importar documentos" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "text-step" }, [_vm._v("Importar documentos")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-3 trial-step" }, [
      _c("img", { attrs: { src: "/img/stepIcons/step3.svg", alt: "HFy" } }),
      _vm._v(" "),
      _c("span", { staticClass: "text-step" }, [_vm._v("HFy")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }