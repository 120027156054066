import Layout from '@/layout/base/layoutBlank'

export default [{
    path: '/login',
    component: Layout,
    children: [
    {
        name: 'auth-login',
        path: '',
        component: () => import('@/components/auth/loginsso'),
        meta: {
            auth: false
        }
    },    
    {
        name: 'auth-forgot',
        path: 'forgot',
        component: () => import('@/components/auth/forgotPassword'),
        meta: {
            auth: false
        }        
    },
    {
        name: 'auth-expired',
        path: 'expired',
        component: () => import('@/components/auth/expiredPassword'),
        meta: {
            auth: false

        }        
    },
    {
        name: 'auth-migrate',
        path: 'migrate',
        component: () => import('@/components/auth/migrate'),
        meta: {
            auth: false
        }        
    },
    {
        name: 'auth-success',
        path: 'success/:message/:link?/:email?',
        component: () => import('@/components/auth/success'),
        meta: {
            auth: false
        }        
    }, 
    {
        name: 'auth-pendency',
        path: 'pendency',
        component: () => import('@/components/auth/pendency'),
        meta: {
            auth: false
        }        
    },
    {
        name: 'auth-blocked',
        path: 'blocked',
        component: () => import('@/components/auth/blocked'),
        meta: {
            auth: false
        }        
    },
    {
        name: 'exceeded-attempts',
        path: 'exceeded-attempts',
        component: () => import('@/components/auth/exceededAttempts'),
        meta: {
            auth: false
        }        
    },
    {
        name: 'logoff',
        path: 'logoff',
        component: () => import('@/components/auth/logoff'),
        meta: {
            auth: false
        }        
    }]
}]
