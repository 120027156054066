<template>
  <b-modal id="modalVideo" hide-header-close hide-header hide-footer no-close-on-backdrop no-close-on-esc>
    <div class="container-flex">
      <div class="row justify-content-center">
        <div class="col-12">
          <p class="text-title text-center">Bem vindo ao Segfy!</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 pt-3 pb-3">
          <span
            class="text-justify text-desc"
          >Confira nosso video de introdução ao sistema, e entenda como funciona o Segfy:</span>
        </div>
      </div>

      <iframe
        id="ytplayer"
        type="text/html"
        width="720"
        height="540"
        src="https://www.youtube.com/embed/rEPbv2PSwDI?autoplay=0&cc_load_policy=1&disablekb=1&fs=0&color=white"
        frameborder="0"
        allowfullscreen
      ></iframe>

      <div class="row justify-content-center mt-3 mb-3">
        <button class="btn align-self-center buttonProsseguir" @click.stop="nextStep()">Prosseguir</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'modalVideo',
  props: ['showModalVideo'],
  watch: {
    showModalVideo: function (val) {
      if (this.showModalVideo) this.$bvModal.show('modalVideo')
      else this.$bvModal.hide('momodalVideodalbv')
    }
  },
  methods: {
    nextStep () {
      this.$bvModal.hide('modalVideo')
      window.Vue.onboarding.driver.nextStep()
    }
  }
}
</script>
<style>
.buttonProsseguir {
  padding: 7px 15%;
  background: #d25032;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.text-title {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #666666;
}
.text-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #666666;
}
#ytplayer {
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
}
</style>
