var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      ref: "dropdown-notifications",
      staticClass: "dropdown-notifications mr-1",
      attrs: { right: "", "no-caret": "" },
      on: { show: _vm.showMenu, hide: _vm.hideMenu }
    },
    [
      _c("template", { slot: "button-content" }, [
        _c(
          "div",
          { staticClass: "navbar-icon", class: _vm.menuAtivo ? "active" : "" },
          [
            _c("i", {
              staticClass: "bell lnr lnr-alarm p-2",
              class: _vm.numberOfNewNotifications > 0 ? "bell-ring" : ""
            })
          ]
        ),
        _vm._v(" "),
        _vm.numberOfNewNotifications > 0
          ? _c("span", { staticClass: "badge badge-primary badge-dot" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.numberOfNewNotifications === 0
        ? _c("div", { staticClass: "notification-title font-weight-normal" }, [
            _vm._v("\n        Não há novas notificações\n    ")
          ])
        : _vm.numberOfNewNotifications === 1
          ? _c("div", { staticClass: "notification-title" }, [
              _vm._v("\n        Há 1 nova notificação\n    ")
            ])
          : _c("div", { staticClass: "notification-title" }, [
              _vm._v(
                "\n        Há " +
                  _vm._s(_vm.numberOfNewNotifications) +
                  " novas notificações\n    "
              )
            ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notifications-list" },
        [
          _c(
            "b-list-group",
            { attrs: { flush: "" } },
            [
              _c(
                "perfect-scrollbar",
                {
                  ref: "scrollDiv",
                  staticStyle: { "max-height": "350px" },
                  attrs: { options: { wheelPropagation: true } }
                },
                _vm._l(_vm.notifications, function(notification) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: notification.id,
                      staticClass: "custom-list-item d-flex mx-2",
                      class: { "opacity-50": notification.lido },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.markAsRead($event, notification)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "custom-list-item-icon mr-3 my-auto ",
                        class: _vm._f("icon")(notification.tipo)
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "custom-list-item-content w-100 d-block"
                        },
                        [
                          _c("h6", { staticClass: "text-body" }, [
                            _vm._v(_vm._s(notification.titulo))
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(notification.descricao))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "mt-2 d-flex justify-content-between"
                            },
                            [
                              _c(
                                "small",
                                { staticClass: "text-light text-nowrap" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getNotificationTime(notification.data)
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !notification.lido
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-light text-nowrap mark-as-read",
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.markAsRead(
                                            $event,
                                            notification,
                                            false
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("small", [
                                        _vm._v("Marcar como lido "),
                                        _c("i", {
                                          staticClass: "fas fa-check ml-1"
                                        })
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("b-list-group-item", { staticClass: "p-0" }, [
                _c(
                  "a",
                  {
                    staticClass: "d-block bg-white text-center small p-3",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.showAllNotifications()
                      }
                    }
                  },
                  [_vm._v("Exibir todas as notificações")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }