import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

import auth from './modules/auth'
import onboarding from './modules/onboarding'
import settings from './modules/settings'
import newOnboarding from './modules/newOnboarding'

Vue.use(Vuex)

const vuexCookie = new VuexPersistence({
    restoreState: (key, storage) => Cookies.getJSON(key),
    saveState: (key, state, storage) => Cookies.set(key, state, {
      expires: 1,
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    }),
    modules: ['auth', 'settings']
})

var store = new Vuex.Store({
    modules: {
      auth,
      onboarding,
      settings,
      newOnboarding
    },
    plugins: [vuexCookie.plugin]
})

store.check = function () {
  return Cookies.getJSON('vuex')
}

Vue.$store = store

export default store
