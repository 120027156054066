var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "nav-item navbar-text navbar-search-box p-0 active flex-fill"
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link d-block d-md-none",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleSearch($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              ref: "toggleSearchWrapper",
              staticClass: "navbar-icon rounded-lg",
              class: { active: _vm.searchBarCollapsed }
            },
            [
              _c("i", {
                staticClass: "navbar-user-icon ion ion-ios-search p-2"
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "searchWrapper",
          staticClass: "w-lg-auto search-wrapper px-2 px-sm-0",
          class: { active: _vm.searchBarCollapsed },
          attrs: { align: "center" }
        },
        [
          _c("div", [
            _c("i", {
              staticClass: "ion ion-ios-search navbar-icon align-middle"
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-fill" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value,
                      expression: "value"
                    }
                  ],
                  ref: "inputSearch",
                  staticClass: "navbar-input-search bordered",
                  attrs: {
                    type: "text",
                    "x-webkit-speech": "",
                    placeholder: "Busca rápida",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.value },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.value = $event.target.value
                      },
                      function($event) {
                        _vm.debounceSearch($event.target.value)
                      }
                    ],
                    keydown: [
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown"
                          ])
                        ) {
                          return null
                        }
                        return _vm.onDown($event)
                      },
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k($event.keyCode, "up", 38, $event.key, [
                            "Up",
                            "ArrowUp"
                          ])
                        ) {
                          return null
                        }
                        return _vm.onUp($event)
                      },
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.hit($event)
                      },
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape"
                          ])
                        ) {
                          return null
                        }
                        return _vm.reset($event)
                      }
                    ],
                    focus: _vm.show,
                    blur: _vm.hide
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-search dropdown-menu w-100",
                    class: {
                      "d-block":
                        (_vm.hasItems || _vm.searched) &&
                        _vm.showResult &&
                        _vm.value.length > 0
                    },
                    attrs: { id: "search-menu" }
                  },
                  [
                    _c(
                      "perfect-scrollbar",
                      {
                        ref: "scrollDiv",
                        staticClass: "mobileBox shadow-sm",
                        staticStyle: { "max-height": "350px" },
                        attrs: { options: { wheelPropagation: true } }
                      },
                      [
                        _vm._l(_vm.items, function(item, index) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: item.guid,
                              ref: item.guid,
                              refInFor: true,
                              staticClass:
                                "custom-list-item position-relative d-flex",
                              class: _vm.activeClass(index),
                              on: {
                                click: function($event) {
                                  _vm.setActive(index)
                                  _vm.hit(item)
                                }
                              }
                            },
                            [
                              item.type === 0
                                ? _c("i", {
                                    staticClass:
                                      "custom-list-item-icon mr-3 my-auto",
                                    class: _vm._f("categoriaIcone")(
                                      item.category
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.type === 0
                                ? _c("i", {
                                    staticClass: "action-icon",
                                    class: _vm._f("acaoIcone")(item.actionType)
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.type === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-list-item-content w-100 d-block"
                                    },
                                    [_c("h5", [_vm._v(_vm._s(item.title))])]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.type === 1
                                ? _c("i", {
                                    staticClass:
                                      "custom-list-item-icon mr-3 my-auto",
                                    class: _vm._f("categoriaIcone")(
                                      item.category
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.type === 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-list-item-content w-100 d-block"
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getCategoriaNome(item.category)
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("h5", { staticClass: "py-1" }, [
                                        _vm._v(_vm._s(item.title))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.fieldName !== null,
                                              expression:
                                                "item.fieldName !== null"
                                            }
                                          ],
                                          staticClass: "small text-muted"
                                        },
                                        [
                                          _vm._v(_vm._s(item.fieldName) + ": "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.highlight(index)
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.itensFilterType1.length === 0
                          ? _c(
                              "b-dropdown-item",
                              {
                                staticClass:
                                  "custom-list-item position-relative d-flex",
                                on: {
                                  click: function($event) {
                                    _vm.openMovidesk()
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "custom-list-item-icon mr-3 my-auto ion ion-ios-help-circle-outline"
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-list-item-content w-100 d-block"
                                  },
                                  [
                                    _vm.termosAjuda()
                                      ? _c(
                                          "h5",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: { color: "#1e70cd" }
                                          },
                                          [_vm._v("Central de Ajuda")]
                                        )
                                      : _c(
                                          "h5",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: { color: "#1e70cd" }
                                          },
                                          [
                                            _vm._v(
                                              'Pesquisar "' +
                                                _vm._s(_vm.value) +
                                                '" na central de ajuda'
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.loading
                    ? _c("div", { staticClass: "search-loading-icon" }, [
                        _c("i", { staticClass: "fas fa-spinner fa-spin" })
                      ])
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }