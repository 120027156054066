import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/segurados',
    component: Layout,
    children: [
        {
            name: 'segurados-painel',
            path: 'painel',
            component: () => import('@/components/segurados/painel'),
            meta: {
                auth: true
            }
        },
        {
            name: 'segurados-novo',
            path: 'novo',
            component: () => import('@/components/segurados/novo'),
            meta: {
                auth: true
            }
        }
    ]
}]
