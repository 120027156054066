import Vue from 'vue'
import App from './app'
import store from './store'
import router from './router'

import './plugins/axios'

import 'vuejs-noty/dist/vuejs-noty.css'

import BootstrapVue from 'bootstrap-vue'
import globals from './globals'
import Popper from 'popper.js'
import VueNoty from 'vuejs-noty'
import moment from 'moment'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import Vue2Filters from 'vue2-filters'
import BlockUI from 'vue-blockui'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import Vue2TouchEvents from 'vue2-touch-events'
import Hub from './hub'
import Driver from './driver'
import VueCarousel from 'vue-carousel'
import VueObserveVisibility from 'vue-observe-visibility'
import VueScrollTo from 'vue-scrollto'
import UUID from 'vue-uuid'

moment.locale('pt-br')

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

var Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false
  },
  currency: {
    symbol: 'R$',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true
  },
  pluralize: {
    includeNumber: false
  },
  ordinal: {
    includeNumber: false
  }
}

Vue.use(BootstrapVue)
Vue.use(VueNoty)
Vue.use(VueTheMask)
Vue.use(money)
Vue.use(BlockUI)
Vue.use(Vue2Filters, Vue2FiltersConfig)
Vue.use(Vue2TouchEvents)
Vue.use(VueNumberInput)
Vue.use(VueCarousel)
Vue.use(VueObserveVisibility)
Vue.use(VueScrollTo)
Vue.use(UUID)

// Global RTL flag
Vue.mixin({
    data: globals,
    methods: {
      setValue (data, prop, value) {
        Vue.set(data, prop, value)
      },
      deleteValue (data, prop) {
        Vue.delete(data, prop)
      },
      disableAutoComplete () {
        let elements = document.querySelectorAll('[autocomplete="off"]')

        if (!elements) {
          return
        }

        elements.forEach(element => {
          element.setAttribute('readonly', 'readonly')
          element.style.backgroundColor = 'inherit'

          setTimeout(() => {
            element.removeAttribute('readonly')
          }, 500)
        })
      },
      handleResize (e) {
        this.isXS = window.innerWidth < 576
        this.isSM = window.innerWidth >= 576 && window.innerWidth < 768
        this.isMD = window.innerWidth >= 768 && window.innerWidth < 992
        this.isLG = window.innerWidth >= 992 && window.innerWidth < 1200
        this.isXL = window.innerWidth >= 1200 && window.innerWidth < 1600
        this.isXXL = window.innerWidth >= 1600
        if (this.isXS) this.screenSize = 'xs'
        if (this.isSM) this.screenSize = 'sm'
        if (this.isMD) this.screenSize = 'md'
        if (this.isLG) this.screenSize = 'lg'
        if (this.isXL) this.screenSize = 'xl'
        if (this.isXXL) this.screenSize = 'xl'
      },
      setSegfyer (val) {
        this.isSegfyer = val
      }
    },
    created () {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    },
    mounted () {
      this.disableAutoComplete()
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.handleResize)
    }
})

// TODO: SignalR
// console.log('Segfy!')
Vue.prototype.moment = moment
Vue.prototype.hub = Hub
Vue.prototype.onboarding = Driver

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
      return value
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
  })
  return formatter.format(value)
})

Vue.filter('toDate', function (value) {
  if (value === undefined || value === null) {
    return ''
  }
  return moment(value).format('DD/MM/YYYY')
})

Vue.filter('toDateTime', function (value) {
  if (value === undefined || value === null) {
    return ''
  }
  return moment(value).format('DD/MM/YYYY HH:mm')
})

Vue.filter('toTime', function (value) {
  if (value === undefined || value === null) {
    return ''
  }
  return moment(value).format('HH:mm')
})

Vue.filter('companyName', function (value) {
  if (!value) return
  let words = value.split('_')
  let result = ''
  let i = 0
  for (i; i < words.length; i++) {
    result += words[i].charAt(0).toUpperCase() + words[i].slice(1) + ' '
  }
  return result.trim()
})

window.Vue = new Vue({
    router,
    store,
    mixins: [Vue2Filters.mixin],
    render: h => h(App)
})

window.Vue.onboarding.driver.start()

window.Vue.$http.interceptors.request.use(function (config) {
    config.withCredentials = true
    const token = 'Bearer ' + window.Vue.$store.state.auth.token
    config.headers.Authorization = token
    return config
})

window.Vue.$httpNoInterceptor.interceptors.request.use(function (config) {
  config.withCredentials = true
  const token = 'Bearer ' + window.Vue.$store.state.auth.token
  config.headers.Authorization = token
  return config
})

window.Vue.$http.interceptors.response.use(
    (response) => {
       switch (response.data.code) {
           case -1:
            return Promise.resolve(response.data)
           case 2:
            window.Vue.$store.state.auth.hash = response.data.data.token
            window.Vue.$router.push({ name: 'auth-expired' })
             break
           case 3:
            window.Vue.$router.push('/login/blocked')
             break
           case 4:
            window.Vue.$router.push('/login/blocked?attempts=1')
             break
           case 5:
            window.Vue.$router.push('/login/blocked')
             break
           default:
               return Promise.resolve(response)
         }
   },
       (error) => {
          if (error.response === undefined) {
            window.Vue.$noty.error('Oops... Ocorreu um erro inesperado.<br/>Por favor, tente novamente em alguns minutos ou entre em contato com nossa suporte se o problema persistir.')
          } else if (error.response.status === 401) {
            window.Vue.$store._actions['auth/forgetUser'][0]()
            window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
          } else if (error.response.status === 503) {
            window.Vue.$noty.error('O seu Segfy está em atualização. Aguarde alguns minutos e tente novamente')
          } else {
            window.Vue.$noty.error('A requisição falhou :(')
          }
          return Promise.reject(error.response)
       }
)

window.Vue.$httpNoInterceptor.interceptors.response.use(
  (response) => {
       return Promise.resolve(response)
 },
     (error) => {
        if (error.response.status === 401) {
          window.Vue.$store._actions['auth/forgetUser'][0]()
          window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
        }
        return Promise.reject(error.response)
     }
)

window.Vue.$httpAutomation.interceptors.request.use(function (config) {
  config.withCredentials = false
  return config
})

window.Vue.$httpCookiesOnly.interceptors.request.use(function (config) {
  config.withCredentials = true
  return config
})

if (store.state.auth.isAuth) {
  window.Vue.hub.connectionFaye.start()

  window.Vue.hub.connectionFaye.sub('logoff', (arg1) => {
    if (arg1 !== null && arg1 !== 'login' + window.Vue.hub.connectionFaye.uid) {
      if (typeof arg1 === 'object') {
          if (arg1.permissao) {
            window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
          } else {
              globals().forcelogoff = true
              window.Vue.logoutLegado(globals().forcelogoff)
              window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
          }
      } else {
          if (arg1 !== null && arg1 === window.Vue.hub.connectionFaye.uid) {
            window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
          } else {
            globals().forcelogoff = true
            window.Vue.logoutLegado(globals().forcelogoff)
            window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
          }
      }
      window.Vue.logoutLegado(globals().forcelogoff)
      window.Vue.$store._actions['auth/forgetUser'][0]()
    }
  }, true, false)
}

window.Vue.$mount('#app')
