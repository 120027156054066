import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/relatorios',
    component: Layout,
    children: [
        {
            name: 'relatorios-orcamentos',
            path: 'orcamentos',
            component: () => import('@/components/relatorios/orcamentos'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-producao',
            path: 'producao',
            component: () => import('@/components/relatorios/producao'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-mix-carteira',
            path: 'mixCarteira',
            component: () => import('@/components/relatorios/mixCarteira'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-pendencia-emissao',
            path: 'pendenciaEmissao',
            component: () => import('@/components/relatorios/pendenciaEmissao'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-renovacoes',
            path: 'renovacoes',
            component: () => import('@/components/relatorios/renovacoes'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-analise-financeira',
            path: 'analiseFinanceira',
            component: () => import('@/components/relatorios/analiseFinanceira'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-comissoes',
            path: 'comissoes',
            component: () => import('@/components/relatorios/comissoes'),
            meta: {
                auth: true
            }
        },
        {
            name: 'relatorios-pagamento-comissoes',
            path: 'pagamentosComissao',
            component: () => import('@/components/relatorios/pagamentosComissao'),
            meta: {
                auth: true
            }
        }
    ]
}]
