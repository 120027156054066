<template>
    <b-modal id="modal-plugin" size="lg">
        <div slot="modal-title">
                <div class="d-flex justify-content-between align-items-center" v-if="status === 1">
                    <b-form-select plain v-model="valor" :options="options" size="md" @change="setDelay"/>
                </div>
        </div>
        <transition name="fade" mode="out-in">
            <div key="status1" v-if="status === 1">
                <h2 class="text-center">Seu navegador ainda não possui a Extensão Segfy!</h2>
                <p class="text-center my-3">Instale agora mesmo, e nossos robôs irão agilizar para você <br> vários processos dentro dos portais das seguradoras.</p>
                
                <img class="d-block mx-auto" :src="'../img/tutorial/tutorial-plugin.gif'">
                <div class="w-100 d-block mt-5">
                    <b-btn class="d-block mx-auto" variant="primary" size="lg" @click="install">Instalar extensão</b-btn>
                </div>
                <div class="w-100 d-block">
                    <a class="d-block mx-auto mt-3 text-center" href="javascript:void(0)" @click.stop="status = 3">Dúvidas de como instalar? Veja como é rápido e fácil.</a>
                </div>
            </div>
            <div key="status2" v-if="status === 2">
                <h2 class="text-center">Já terminou a instalação?</h2>
                <p class="text-center">Caso tenha instalado, atualize a página para poder usar o plugin.</p>
                <img class="d-block mx-auto" :src="'../img/tutorial/tutorial-plugin-refresh.jpg'">
                <div class="w-100 d-block mt-5">
                    <b-btn class="d-block mx-auto" variant="primary" size="lg" @click.stop="refreshPage">Atualizar a página</b-btn>
                </div>
                <div class="w-100 d-block">
                    <a class="d-block mx-auto mt-3 text-center" href="javascript:void(0)" @click.stop="status = 3">Dúvidas de como instalar? Veja como é rápido e fácil.</a>
                </div>
            </div>
            <div key="status3" v-if="status === 3">
                <h2 class="text-center">Tutorial de instalação</h2>
                <b-alert variant="secondary" :show="isChrome" class="d-flex flex-column p-3 my-5">
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-npitems-center justify-content-center mr-2">1</div><span><a target="_blank" href="https://chrome.google.com/webstore/detail/segfy-multic%C3%A1lculo/llckbbdajkkihfabigiedajkgofgmlgm"> Clique aqui</a> - você será redirecionado para a Chrome Web Store </span></div>
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-items-center justify-content-center mr-2">2</div><span> Clique no botão <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-chrome-1">Usar no Chrome <i class="fas fa-info-circle"></i></b-badge></span></div>
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-items-center justify-content-center mr-2">3</div><span> Clique em <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-chrome-2">Adicionar extensão <i class="fas fa-info-circle"></i></b-badge> no pop-up</span></div>
                </b-alert>
                <b-alert variant="secondary" :show="isFirefox" class="d-flex flex-column p-3 my-5">
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-items-center justify-content-center mr-2">1</div><span><a target="_blank" href="https://multicalculo.segfy.com/extension/multicalculo-extension?version=4.0.4"> Clique aqui</a> - para baixar o plugin </span></div>
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-items-center justify-content-center mr-2">2</div><span> Clique em <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-firefox-1"> Salvar arquivo <i class="fas fa-info-circle"></i></b-badge></span></div>
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-items-center justify-content-center mr-2">3</div><span> No topo direito clique no  <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-firefox-2"> ícone de downloads <i class="fas fa-info-circle"></i></b-badge> e <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-firefox-3"> abra o arquivo<i class="fas fa-info-circle"></i></b-badge></span></div>
                    <div class="d-flex flex-row align-items-center p-3"><div class="circle d-flex align-items-center justify-content-center mr-2">3</div><span> Clique em <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-firefox-4"> adicionar <i class="fas fa-info-circle"></i></b-badge>, de <b-badge href="javascript:void(0)" variant="outline-info" id="tutorial-plugin-firefox-5"> check no box <i class="fas fa-info-circle"></i></b-badge> e então "ok, entendi".</span></div>
                </b-alert>
                <b-popover target="tutorial-plugin-chrome-1" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-chrome-1.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <b-popover target="tutorial-plugin-chrome-2" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-chrome-2.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <b-popover target="tutorial-plugin-firefox-1" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-firefox-1.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <b-popover target="tutorial-plugin-firefox-2" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-firefox-2.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <b-popover target="tutorial-plugin-firefox-3" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-firefox-3.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <b-popover target="tutorial-plugin-firefox-4" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-firefox-4.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <b-popover target="tutorial-plugin-firefox-5" triggers="hover focus" class="popover-secondary" placement="bottom">
                    <img :src="'../img/tutorial/tutorial-plugin-firefox-5.jpg'" style="width:100%; height:100%" />
                </b-popover>
                <p class="text-center my-5">Instalou? Agora é só atualizar a página para usar o nosso plugin</p>
                <div class="w-100 d-block">
                    <b-btn class="d-block mx-auto" variant="primary" size="lg" @click.stop="refreshPage">Atualizar a página</b-btn>
                </div>
                <div class="w-100 d-block">
                    <a class="d-block mx-auto mt-3" href="javascript:void(0)" @click.stop="status = 1"><i class="lnr lnr-chevron-left"></i> Voltar</a>
                </div>
            </div>
        </transition>
        <div slot="modal-footer" class="w-100 modal-footer-plugin"></div>
    </b-modal>
</template>
<style lang="scss">
    @import "../../vendor/styles/_appwork/_include";
    @import "../../vendor/styles/_appwork/_variables";

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s ease;

    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0
    }

    #modal-plugin .modal-footer {
        border: none;
        padding: 0;
    }
    #modal-plugin .modal-body {
        min-height: 250px
    }
    .circle {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: $segfy_1;
        color: $white
    }
</style>
<script>
import { SweetModal } from 'sweet-modal-vue'
import Cookies from 'js-cookie'
export default {
    name: 'modalConfirmacao',
    data () {
        return {
            status: 1,
            valor: 0,
            options: [{ value: 0, text: 'Adiar instalação' },
            { value: 1, text: 'Para amanhã' },
            { value: 7, text: 'Para próxima semana' },
            { value: 30, text: 'Para próximo mês' },
            { value: 365, text: 'Não quero instalar' }],
            isFirefox: false,
            isChrome: false
        }
    },
    created () {
        var nav = navigator.userAgent.toLowerCase()
        this.isFirefox = nav.includes('firefox')
        this.isChrome = nav.includes('chrome')
    },
    components: {
        SweetModal
    },
    methods: {
        openModal (options) {
            this.$bvModal.show('modal-plugin')
        },
        setDelay () {
            this.$bvModal.hide('modal-plugin')
            Cookies.set('modalplugin', 'true', {
                expires: this.valor,
                domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
            })
        },
        install () {
            if (this.isChrome) {
                var win = window.open('https://chrome.google.com/webstore/detail/segfy-multic%C3%A1lculo/llckbbdajkkihfabigiedajkgofgmlgm', '_blank')
                win.focus()
            } else {
                this.firefoxInstall()
                // this.$http({
                //     method: 'get',
                //     url: 'files/multicalculo-firefox.xpi',
                //     responseType: 'arraybuffer'
                // })
                // .then(response => {
                //     this.forceFileDownload(response)  
                // })
                // .catch(() => window.Vue.$noty.error('Houve problema no download da extensão, por favor, tente novamente.'))
            }
            this.status = 2
        },
        forceFileDownload (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'multicalculo-firefox.xpi')
            document.body.appendChild(link)
            link.click()
        },
        refreshPage () {
            this.$router.go()
        },
        firefoxInstall () {
            let url = 'https://multicalculo.segfy.com/extension/multicalculo-extension?version'
            let params = {
                'Foo': { URL: url,
                        IconURL: null,
                        Hash: null,
                        toString: function () { return url }
                }
            }
            // eslint-disable-next-line 
            InstallTrigger.install(params)

            return false
        }
    }
}
</script>
