
<template>
  <b-nav-item-dropdown
    no-caret
    ref="dropdownAppsMenu"
    class="dropdown-user mr-2"
    @show="showMenu"
    @hide="hideMenu"
  >
    <template slot="button-content">
      <div class="navbar-icon rounded-lg" :class="menuAtivo ? 'active' : ''">
        <i class="navbar-user-icon fa fa-th p-2"></i>
      </div>
    </template>
    <template>
      <div class="frame-container">
        <div class="frame-content ff">
          <iframe
            data-hj-allow-iframe
            :src="candybox"
            frameborder="no"
            allowtransparency="true"
            ref="iframe"
            id="apps-menu"
          />
        </div>
      </div>
    </template>
  </b-nav-item-dropdown>
</template>

<style lang="scss">
@import "../../../style/common.scss";
#apps-menu {
  height: 100%;
  border: none;
}

.frame-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 240px;
  padding: 0;
  border: none;

  .frame-content > iframe {
    height: 100%;
    width: 240px;
  }

  .dropdown-user {
    a {
      display: flex;
      align-items: center;
      pointer-events: all !important;
    }
  }
}

.navbar-user-icon {
  font-size: 24px;
  color: $Grey_7;
  display: block;
}

.dropdown-user .dropdown-item:active {
  background-color: #e7e7e7 !important;
}

@media (max-width: 768px) {
  .dropdown-user .dropdown-toggle::after {
    display: none;
  }
}
</style>

<script>
export default {
  name: 'appsMenu',
  metaInfo: {
    title: 'Apps menu'
  },
  props: {
    menuOpened: { type: Boolean, default: false }
  },
  methods: {
    showMenu: function () {
      this.menuAtivo = true
    },
    hideMenu: function () {
      this.menuAtivo = false
    },
    receiveMessage (event) {
      if (event.data && event.data.action === 'close') {
        this.$refs.dropdownAppsMenu.hide()
        this.menuAtivo = false
      }
    }
  },

  mounted () {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  },
  data: function () {
    return {
      menuAtivo: false,
      candybox: process.env.VUE_APP_CANDYBOX
        ? process.env.VUE_APP_CANDYBOX
        : 'http://localhost:3000'
    }
  }
}
</script>
