<template>
    <div class="h-100 d-flex flex-column">
        <onboarding-navbar />

        <transition name="fade" mode="out-in" duration="300">            
            <router-view></router-view>
        </transition>    
    </div>
</template>

<style>
    html, body, #app {
        height: 100%; 
    }

    .onboarding-content {
        background-color: #EDF1F9;
        overflow-x: auto;
    }   
</style>

<script>
import OnboardingNavbar from './navbar'

export default {
    name: 'onboarding-layout',
    components: {
        'onboarding-navbar': OnboardingNavbar
    }
}
</script>
