
import globals from './globals'
import faye from 'faye'
import store from './store'

let conn = null
let connMulticalculo = null
let connBuscas = null

function multipleSessionsLogoff (arg1) {
    if (typeof arg1 === 'object') {
        if (arg1.permissao) {
          window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
        } else {
            globals().forcelogoff = true
            window.Vue.logoutLegado(globals().forcelogoff)
            window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
        }
    } else {
        if (arg1 !== null && arg1 === window.Vue.hub.connectionFaye.uid) {
          window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
        } else {
          globals().forcelogoff = true
          window.Vue.logoutLegado(globals().forcelogoff)
          window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
        }
    }
}

function subscribe (channel, myCallback, corretora = false, geral = false) {
    if (conn == null) {
        return
    }
    var userChannel = globals().cryptChannel(`${store.state.auth.assinaturaId}-${store.state.auth.usuarioId}-${store.state.auth.segfyerId}`)

    let completeChannel = '/' + process.env.VUE_APP_FAYE + userChannel + '/' + channel

    if (completeChannel in conn._channels._channels) {
        return
    }

    if (completeChannel.includes('logoff')) {
        conn.subscribe(completeChannel, (arg1) => {
            var idArmazenado = localStorage.getItem('uidLogin')
            if ((arg1 !== null && arg1 !== 'login' + window.Vue.hub.connectionFaye.uid) || (idArmazenado !== null && arg1 !== null && arg1 !== idArmazenado)) {
                if (idArmazenado !== null) {
                    localStorage.removeItem('uidLogin')
                }
                multipleSessionsLogoff(arg1)
                window.Vue.logoutLegado(globals().forcelogoff)
                window.Vue.$store._actions['auth/forgetUser'][0]()
            }
          }, true, false)
    } else {
        conn.subscribe(completeChannel, function (arg1) {
            if (myCallback && typeof (myCallback) === 'function') {
                myCallback(arg1)
            }
        })
    }

    if (corretora === true) {
        if (store.state.auth.administrador === true) {
            var corretoraAdmin = globals().cryptChannel(`admin${store.state.auth.assinaturaId}`)

            completeChannel = '/' + process.env.VUE_APP_FAYE + corretoraAdmin + '/' + channel
            if (!(completeChannel in conn._channels._channels)) {
                conn.subscribe(completeChannel, function (arg1) {
                    if (myCallback && typeof (myCallback) === 'function') {
                        myCallback(arg1)
                    }
                })
            }
        }
        var corretoraTodos = globals().cryptChannel(store.state.auth.assinaturaId)

        completeChannel = '/' + process.env.VUE_APP_FAYE + corretoraTodos + '/' + channel
        if (!(completeChannel in conn._channels._channels)) {
            conn.subscribe(completeChannel, function (arg1) {
                if (myCallback && typeof (myCallback) === 'function') {
                    myCallback(arg1)
                }
            })
        }
    }

    if (geral === true) {
        if (store.state.auth.administrador === true) {
            var globalAdmin = globals().cryptChannel('adminsegfy')
            completeChannel = '/' + process.env.VUE_APP_FAYE + globalAdmin + '/' + channel
            if (!(completeChannel in conn._channels._channels)) {
                conn.subscribe(completeChannel, function (arg1) {
                    if (myCallback && typeof (myCallback) === 'function') {
                        myCallback(arg1)
                    }
                })
            }
        }
        var globalTodos = globals().cryptChannel('segfy')

        completeChannel = '/' + process.env.VUE_APP_FAYE + globalTodos + '/' + channel
        if (!(completeChannel in conn._channels._channels)) {
            conn.subscribe(completeChannel, function (arg1) {
                if (myCallback && typeof (myCallback) === 'function') {
                    myCallback(arg1)
                }
            })
        }
    }
}

function subscribeMulticalculo (channel, myCallback, corretora = false, geral = false) {
    if (connMulticalculo == null) {
        return
    }
    var userChannel = globals().cryptChannel(`${store.state.auth.assinaturaId}-${store.state.auth.usuarioId}-${store.state.auth.segfyerId}`)

    let completeChannel = '/' + process.env.VUE_APP_FAYE + userChannel + '/' + channel

    if (completeChannel in connMulticalculo._channels._channels) {
        return
    }

    connMulticalculo.subscribe(completeChannel, function (arg1) {
        if (myCallback && typeof (myCallback) === 'function') {
            myCallback(arg1)
        }
    })

    if (corretora === true) {
        if (store.state.auth.administrador === true) {
            var corretoraAdmin = globals().cryptChannel(`admin${store.state.auth.assinaturaId}`)

            completeChannel = '/' + process.env.VUE_APP_FAYE + corretoraAdmin + '/' + channel
            if (!(completeChannel in connMulticalculo._channels._channels)) {
                connMulticalculo.subscribe(completeChannel, function (arg1) {
                    if (myCallback && typeof (myCallback) === 'function') {
                        myCallback(arg1)
                    }
                })
            }
        }
        var corretoraTodos = globals().cryptChannel(store.state.auth.assinaturaId)

        completeChannel = '/' + process.env.VUE_APP_FAYE + corretoraTodos + '/' + channel
        if (!(completeChannel in connMulticalculo._channels._channels)) {
            connMulticalculo.subscribe(completeChannel, function (arg1) {
                if (myCallback && typeof (myCallback) === 'function') {
                    myCallback(arg1)
                }
            })
        }
    }

    if (geral === true) {
        if (store.state.auth.administrador === true) {
            var globalAdmin = globals().cryptChannel('adminsegfy')
            completeChannel = '/' + process.env.VUE_APP_FAYE + globalAdmin + '/' + channel
            if (!(completeChannel in connMulticalculo._channels._channels)) {
                connMulticalculo.subscribe(completeChannel, function (arg1) {
                    if (myCallback && typeof (myCallback) === 'function') {
                        myCallback(arg1)
                    }
                })
            }
        }
        var globalTodos = globals().cryptChannel('segfy')

        completeChannel = '/' + process.env.VUE_APP_FAYE + globalTodos + '/' + channel
        if (!(completeChannel in connMulticalculo._channels._channels)) {
            connMulticalculo.subscribe(completeChannel, function (arg1) {
                if (myCallback && typeof (myCallback) === 'function') {
                    myCallback(arg1)
                }
            })
        }
    }
}

function subscribeBuscas (channel, myCallback, token) {
    if (connBuscas == null) {
        return
    }
    let environment = process.env.VUE_APP_FAYE
    if (environment === 'local') {
        environment = 'dev'
    }
    let completeChannel = '/faye/' + environment + channel + '_' + token
    // console.log('Canal inscrito: ', completeChannel)
    if (completeChannel in connBuscas._channels._channels) {
        return
    }

    connBuscas.subscribe(completeChannel, function (arg1) {
        if (myCallback && typeof (myCallback) === 'function') {
            myCallback(arg1)
        }
    })
}

const connectionFaye = {
    start: function () {
        if (conn === null) {
            conn = new faye.Client('https://gestao.fayews.segfy.com/faye', { retry: 5 })

            this.uid = store.state.auth.guid
        }
    },
    pub: function (channel, data) {
        if (conn !== null) {
            var userChannel = globals().cryptChannel(`${store.state.auth.assinaturaId}-${store.state.auth.usuarioId}-${store.state.auth.segfyerId}`)
            conn.publish('/' + process.env.VUE_APP_FAYE + userChannel + '/' + channel, data)
            try {
                localStorage.setItem('uidLogin', data)
            } catch (error) {
                console.error('Falha ao salvar em Local Storage: ', error.message)
            }
        }
    },
    pubTo: function (channel, data, usuarioid, assinatura) {
        if (conn !== null) {
            var userChannel = globals().cryptChannel(`${usuarioid}-${assinatura}`)
            conn.publish('/' + `${process.env.VUE_APP_FAYE}` + userChannel + '/' + channel, data)
        }
    },
    sub: subscribe,
    disconnect: function () {
        if (conn !== null) {
            conn.disconnect()
            conn = null
        }
    },
    getFayeState: function () {
        if (conn !== null) {
            return conn
        }
        return 0
    }
}

const connectionFayeMulticalculo = {
    start: function () {
        if (connMulticalculo === null) {
            connMulticalculo = new faye.Client('https://mc.fayews.segfy.com/faye', { retry: 5 })

            this.uid = store.state.auth.guid
        }
    },
    pub: function (channel, data) {
        if (connMulticalculo !== null) {
            var userChannel = globals().cryptChannel(`${store.state.auth.assinaturaId}-${store.state.auth.usuarioId}-${store.state.auth.segfyerId}`)
            connMulticalculo.publish('/' + process.env.VUE_APP_FAYE + userChannel + '/' + channel, data)
        }
    },
    pubTo: function (channel, data, usuarioid, assinatura) {
        if (connMulticalculo !== null) {
            var userChannel = globals().cryptChannel(`${usuarioid}-${assinatura}`)
            connMulticalculo.publish('/' + `${process.env.VUE_APP_FAYE}` + userChannel + '/' + channel, data)
        }
    },
    sub: subscribeMulticalculo,
    disconnect: function () {
        if (connMulticalculo !== null) {
            connMulticalculo.disconnect()
            connMulticalculo = null
        }
    },
    getFayeState: function () {
        if (connMulticalculo !== null) {
            return connMulticalculo
        }
        return 0
    }
}

const connectionFayeBuscas = {
    start: function () {
        if (connBuscas === null) {
            connBuscas = new faye.Client('https://gestao.fayews.segfy.com/faye', { retry: 5 })

            this.uid = store.state.auth.guid
        }
    },
    sub: subscribeBuscas,
    disconnect: function () {
        if (connBuscas !== null) {
            connBuscas.disconnect()
            connBuscas = null
        }
    },
    getFayeState: function () {
        if (connBuscas !== null) {
            return connBuscas
        }
        return 0
    }
}

export default {
    connectionFaye,
    connectionFayeMulticalculo,
    connectionFayeBuscas
}
